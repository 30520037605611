import React, { Component, lazy, Suspense, PropTypes, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Checkbox, Pagination } from "antd";

import {
  Row,
  Col,
  Spin,
  List,
  Input,
  Tooltip,
  Upload,
  Button,
  Modal,
  message,
  Menu,
  Card,
  Avatar,
  Table,
  Switch,
  Select,
} from "antd";
import {
  BellFilled,
  EditOutlined,
  DeleteOutlined,
  AudioOutlined,
  MailOutlined,
  UserOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./admin.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import { CSVLink } from "react-csv";
import ApiService from "../../services/api-services";
import { Container } from "reactstrap";
import CONFIG from "../../services/config";
import BottomScrollListener from "react-bottom-scroll-listener";
import UserContext from "../../UserContext";
import layer from "../../assets/img/layer.png";
import DoctorImage from "../../assets/img/Doctor.png";
import rating from "../../assets/img/star.png";
import ReactFileReader from "react-file-reader";
import userfile from "../../assets/files/User.csv";
import Form from "antd/lib/form/Form";
import { Option } from "antd/lib/mentions";
var items = [];
const { Search } = Input;
const { confirm } = Modal;
const { SubMenu } = Menu;
var searchItem = [];
var titles = new Map();
const Clear = "Clear";
const Filter = "Filter";
const filterData = (data) =>
  data.map((item) => ({
    key: item.id,
    value: item.name,
    text: item.name,
  }));

export default class DoctorList extends Component {
  static contextType = UserContext;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  constructor(props) {
    super(props);
    this.handleFiles = this.handleFiles.bind(this);
    // console.log(" this.props", this.props)
    var user_id,
      type,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        first_name: this.props.location.state.first_name,
      };
    }

    this.state = {
      token: sessionStorage.getItem("token"),
      permissionData: [],
      page: 1,
      isSearching: false,
      //
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 10,
      serarchPaginationFlag: false,
      //recordsPerPageOptions: [10, 20, 50],
      //
      docData: [],
      dataOrg: [],
      tracks: [],
      speciality: [],
      spec_id: "",
      orgs_id: "",
      hasMoreItems: true,
      doctor: "",
      loadingFirstTime: false,
      isNotFound: false,
      isFocusing: false,
      paginationLoading: false,
      items: [],
      isDisabled: false,
      isLoading: false,
      isDeleteLoading: false,
      isDelLoading: false,
      viewDoctor: [],
      SpeclData: [],
      orgData: [],
      roleData: [],
      formType: type,
      currentTableData: [],
      // formData: {},
      searchTimer: 0,
      lastUpdated: "",
      rowLoader: false,
      formData: {
        first_name: "",
        last_name: "",
        hospital_name: "",
        email: "",
        dob: "",
        spec_id: [],
        role_id: [],
        about: "",
        mobile: "",
        experience: "",
        user_id: "",
        org_id: "",
        MPI: "",
        //filter data selected checkbox
        selectedCheckboxes: [],
        filterVisible: false,
      },
    };
  }
  /*navigation menu*/

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  /** */

  componentDidMount() {
    // if (this.state.token === null || this.state.token === "") {
    //   this.props.history.push("/login");
    //   window.location.reload(true);
    // }
    // // setTimeout(() => {
    // //   this.setState({ isLoading: false });
    // // }, 20000);
    // this.fetchOrganization();
    // sessionStorage.getItem("rolename") === "Super Admin" &&
    //   this.getDoctorList();
    // //sessionStorage.getItem("rolename") === "Super Admin" && this.getOrgDoctor();
    // sessionStorage.getItem("rolename") === "Admin" && this.fetchProviderOrg();
    // this.fetchSpeciality();
    // this.getOrganizationList();
    // this.getRoleList();
    // const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    // if (isLoggedIn === "false") {
    //   this.props.history.push("/login");
    // }
    // sessionStorage.removeItem("userProfile"); //for uploading image
    //--------------------------------
    // above code is before promise
    //--------------------------------
    this.fetchData();
  }

  editBtnClick(
    MPI,
    user_id,
    first_name,
    last_name,
    dob,
    hospital_name,
    email,
    spec_id,
    role_id,
    about,
    mobile,
    experience,
    image_url,
    specialties,
    type
  ) {
    // editBtnClick(user_id) {
    // if(type == 'view') {
    var bodyFormData = new FormData();
    let body = {
      user_id: this.state.formData.user_id,
    };

    ApiService.adddetails.viewDoctor(body).then((data) => {
      if (data.status === 200) {
        this.setState({ SpecialityFullyLoaded: true, viewDoctor: data.data });
      }
    });
    // }
    this.props.history.push({
      pathname: "/add-user",
      state: {
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        dob: dob,
        hospital_name: hospital_name,
        role_id: role_id,
        experience: experience,
        mobile: mobile,
        spec_id: spec_id,
        email: email,
        about: about,
        image_url: image_url,
        MPI: MPI,
        specialties: specialties,
        type,
      },
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetchData);
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      filterVisible: !prevState.filterVisible,
    }));
  };

  handleCustomResetFilter = () => {
    this.toggleFilter();
    if (this.state.selectedCheckboxes?.length > 0) {
      this.setState({
        selectedCheckboxes: [],
        isLoading: false,
        serarchPaginationFlag: false,
      });
      this.fetchData();
    }
  };

  handleCustomFilterConfirm = () => {
    this.setState({ filterVisible: false, isLoading: false });
    if (this.state.selectedCheckboxes?.length > 0) {
      this.setState({ serarchPaginationFlag: true });
      ApiService.general
        .search_doc_for_org({
          org_id: "",
          name: "",
          orgs: this.state.selectedCheckboxes,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({ docData: res.data });
            this.Tablecolumns();
          } else {
            this.setState({ docData: [] });
          }
          this.setState({ isLoading: true });
          // this.setView(res.data)
        })
        .catch((err) => {
          this.setState({ isLoading: true });
        });
    } else {
      this.setState({
        isLoading: false,
        serarchPaginationFlag: false,
      });
      this.fetchData();
    }
  };
  handleCheckboxChange = (value) => {
    this.setState((prevState) => {
      let updatedSelectedCheckboxes;

      if (Array.isArray(prevState.selectedCheckboxes)) {
        // If prevState.selectedCheckboxes is an array, use it
        updatedSelectedCheckboxes = [...prevState.selectedCheckboxes];
      } else {
        // If prevState.selectedCheckboxes is not an array, initialize it as an empty array
        updatedSelectedCheckboxes = [];
      }

      // Check if the checkbox value is already in the selectedCheckboxes array
      if (updatedSelectedCheckboxes.includes(value)) {
        // If it's already in the array, remove it by filtering it out
        updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
          (val) => val !== value
        );
      } else {
        // If it's not in the array, add it by concatenating it to the array
        updatedSelectedCheckboxes.push(value);
      }

      return { selectedCheckboxes: updatedSelectedCheckboxes };
    });
  };
  fetchData = () => {
    //alert("hola1", this.state.currentPage);
    //console.log("hola1", this.state.currentPage);
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.setState({ isLoading: false });

    this.setState({ isLoading: false }, () => {
      //console.log("hola1", this.state.isLoading);
      // set loading state to true(in our case false)
      const promises = [
        this.fetchOrganization(),
        sessionStorage.getItem("rolename") === "Super Admin" &&
        this.getDoctorList(),
        sessionStorage.getItem("rolename") === "Admin" &&
        this.fetchProviderOrg(),
        this.fetchSpeciality(),
        this.getOrganizationList(),
        this.getRoleList(),
      ];

      Promise.all(promises) // wait for all API requests to complete
        .then(() => {
          const isLoggedIn = sessionStorage.getItem("isLoggedIn");

          if (isLoggedIn === "false") {
            this.props.history.push("/login");
          }
          sessionStorage.removeItem("userProfile"); //for uploading image
          //console.log("hola2", this.state.isLoading);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({ isLoading: true });
          }, 500);
          //this.setState({ isLoading: true }); // set loading state to false(in our case true)
        });
    });
  };

  handleSizeChange = (current, size) => {
    //console.log("woop");
    //console.log(`Page: ${current}, Size: ${size}`);
    this.setState({ itemsPerPage: size, currentPage: 1 }, this.fetchData);

    // Perform any action based on the new page size
    // For example, fetch data with the new page size
  };
  getOrgDoctor = () => {
    let body = {
      org_id: this.state.orgs_id,
    };
    ApiService.adddetails
      .orgProvider(body)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ docData: data.data });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  fetchProviderOrg = () => {
    let body = {
      org_id: sessionStorage.getItem("org_id"),
    };
    ApiService.adddetails
      .orgProvider(body)
      .then((data) => {
        if (data.status === 200) {
          this.setState({ docData: data.data });
          //above isLoading:true has been removed
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  fetchSpeciality = () => {
    let body = {
      dob: "",
    };

    ApiService.adddetails
      .getSplType(body)
      .then((data) => {
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          const newData =
            (data.data && data.data.filter((x) => x.status !== 0)) || [];
          this.setState({
            SpecialityFullyLoaded: true,
            SpeclData: newData,
            //isLoading: true,
            //isLoading: true,
            //above isLoading is commented
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  getOrganizationList = () => {
    ApiService.adddetails
      .getOrgList()
      .then((data) => {
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          const newData =
            (data.data && data.data.filter((x) => x.status !== 0)) || [];
          this.setState({
            SpecialityFullyLoaded: true,
            orgData: newData,
            // isLoading: true,
            //above isLoading is commented
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  getRoleList = () => {
    ApiService.adddetails
      .getRoleType()
      .then((data) => {
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          const newData =
            (data.data.response &&
              data.data.response.filter((x) => x.status !== 0)) ||
            [];
          this.setState({
            SpecialityFullyLoaded: true,
            roleData: newData,
            //isLoading: true,
            //above isLoading is commented
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  handleFiles = (files) => {
    this.setState({ isLoading: false });
    const { SpeclData, orgData } = this.state;
    var reader = new FileReader();
    reader.onload = (e) => {
      //Split csv file data by new line so that we can skip first row which is header
      let jsonData = reader.result.split("\n");
      let data = [];
      jsonData.forEach((element, index) => {
        // console.log(jsonData,'data')
        if (index) {
          //Split csv file data by comma so that we will have column data
          const elementRaw = element.split(",");
          // console.log(element, index);
          if (element) {
            let param = {
              npi: elementRaw[0],
              first_name: elementRaw[1],
              last_name: elementRaw[2],
              dob: elementRaw[3],
              email: elementRaw[4],
              password: elementRaw[5],
              mobile: elementRaw[6],
              experience: elementRaw[7],
              hospital: elementRaw[8],
              speciality: elementRaw[9],
              bio: elementRaw[10],
              org_details: { org_id: elementRaw[11], roles: elementRaw[12] },
            };
            data.push(param);
          }
        }
      });
      const options = [],
        orgs = [],
        roles = [];
      // console.log('hii')
      let listspeciality =
        this.state.SpeclData.length > 0 &&
        this.state.SpeclData.map((items, i) => {
          return options.push({
            name: items.name,
            value: items.spec_id,
          });
        }, this);

      let listOrgTag =
        this.state.orgData.length > 0 &&
        this.state.orgData.map((items, i) => {
          return orgs.push({
            org_name: items.name,
            id: items.org_id,
          });
        }, this);

      let listRoleTag =
        this.state.roleData.length > 0 &&
        this.state.roleData.map((items, i) => {
          return roles.push({
            role_name: items.role_name,
            role_id: items.role_id,
          });
        }, this);

      let userbody = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.state.SpeclData.length; j++) {
          for (let k = 0; k < this.state.orgData.length; k++) {
            for (let l = 0; l < this.state.roleData.length; l++) {
              //comparing the column values with the api returned values to fetch the particular ID's
              if (
                data[i]["speciality"] === options[j]["name"] &&
                data[i]["org_details"]["org_id"] === orgs[k]["org_name"] &&
                data[i]["org_details"]["roles"] === roles[l]["role_name"]
              ) {
                this.setState({
                  spec_id: options[j]["value"],
                });
                //if comparison returns true, assign the ID's to the respective variable to send it to api
                data[i]["org_details"]["org_id"] = orgs[k]["id"];
                data[i]["org_details"]["roles"] = [roles[l]["role_id"]];

                let body = {
                  npi: data[i]["npi"],
                  first_name:
                    data[i]["first_name"].charAt(0).toUpperCase() +
                    data[i]["first_name"].slice(1),
                  last_name:
                    data[i]["last_name"].charAt(0).toUpperCase() +
                    data[i]["last_name"].slice(1),
                  dob: data[i]["dob"],
                  hospital_name: data[i]["hospital"],
                  email: data[i]["email"],
                  password: data[i]["password"],
                  mobile: data[i]["mobile"],
                  experience:
                    data[i]["experience"] === ""
                      ? 0
                      : parseInt(data[i]["experience"]),
                  image_url: "",
                  spec_id: this.state.spec_id,
                  about: data[i]["bio"],
                  org_details: [data[i]["org_details"]],
                };
                userbody.push(body);
              }
            }
          }
        }
      }
      ApiService.adddetails
        .adduser(userbody)
        .then((data) => {
          this.setState({ isLoading: true });
          if (data.status === 200) {
            message.success("Successfully added the users");
            // this.getOrgDoctor();
            this.getDoctorList();
            this.props.history.push("/user-list");
            //window.location.reload(true);
          }
        })

        .catch((error) => {
          this.setState({ isLoading: true });
          if (error.data.status === 401) {
            message.error("authentication failed");
          }
          if (error.data.status === 701) {
            message.error("Something went wrong, Try again");
          }
          if (error.data.status === 702) {
            message.error("User already exist");
          }
          if (error.data.status === 709) {
            message.error("Npi already exist");
          }
        });
    };
    reader.readAsText(files[0]);
  };

  showDeleteConfirm(user_id, status) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "this User?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteDoctor(user_id, status);
      },
      onCancel() { },
    });
  }

  deleteDoctor(user_id, status) {
    let body = {
      user_id: user_id,
      status: status === 0 ? 1 : 0,
    };

    ApiService.adddetails
      .deleteDoc(body)
      .then((data) => {
        this.setState({ isDelLoading: true });
        this.setState({ rowLoader: true });

        if (data.status === 200) {
          // message.success("Successfully deleted");

          //this.getOrgDoctor();
          this.getDoctorList();
          //this.setState({ items: [], tracks: [] });
          this.props.history.push("/user-list");
        }
      })
      .catch((error) => {
        this.setState({ isDelLoading: false });
      });
  }

  handleChange = (e, field) => {
    const { orgs_id } = this.state;
    if (field === "org") {
      this.setState({
        orgs_id: e.value,
      });
      return;
    }
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (args, field) => {
    //const dataIndex = args;
    const dataIndex = args == null ? "" : args;
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={"Search Specialty"}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: (item) =>
        field === "name" ? (
          <>
            {/* {this.renderProfile({ url: item['ProviderImg'] })} */}
            {item["Name"]}
          </>
        ) : (
          item
        ),
    };
  };

  Tablecolumns = () => [
    {
      title: "User Name",
      width: "20%",
      ellipsis: true,
      /* api integration here */
      render: (url, record) => {
        return (
          <div
            class="table-doctor"
            className={record.status === 0 ? "hide" : "show"}
          >
            <Avatar
              shape="square"
              size="large"
              icon={
                record.image_url !== "" ? (
                  <img
                    style={{ borderRadius: "50%" }}
                    src={record.image_url}
                  ></img>
                ) : (
                  <UserOutlined />
                )
              }
              style={{ borderRadius: "50%", position: "relative" }}
            />
            <div
              id="table-name"
              style={{
                cursor: "pointer",
                wordBreak: "break-word",
                wordSpacing: "4px",
                marginLeft: "3rem",
                marginTop: "-3.5em",
              }}
              onClick={
                record.status === 1
                  ? () =>
                    this.editBtnClick(
                      record.MPI,
                      record.user_id,
                      record.first_name,
                      record.last_name,
                      record.dob,
                      record.hospital_name,
                      record.email,
                      record.spec_id,
                      record.role_id,
                      record.about,
                      record.mobile,
                      record.experience,
                      record.image_url,
                      record.specialties,
                      "edit"
                    )
                  : null
              }
            >
              <div> {record["first_name"] + " "}</div>
              <div> {record["last_name"] + " "}</div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Specialty",
      dataIndex: "speciality",
      width: "15%",

      ...this.getColumnSearchProps("spec_master.name"),
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["spec_master.name"]}
          </span>
        );
      },
    },
    {
      title: "Organization",
      dataIndex: ["org_dtl", 0, "organization.name"],
      width: "15%",
      filterDropdown: (
        <div
          style={{
            position: "absolute",
            padding: 8,
            width: "300px",
            maxHeight: "220px",
            display: this.state.filterVisible ? "block" : "none",
            background: "#fff", // Add a white background
            border: "1px solid #d9d9d9", // Add a border
            borderRadius: "2px", // Add some border radius
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)", // Add a box shadow
          }}
        >
          <div
            style={{
              maxHeight: "150px", // Adjust the max height to allow space for buttons
              overflowY: "auto",
            }}
          >
            {this.state.orgData
              .map((item) => ({
                id: item.org_id,
                name: item.name,
              }))
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((filterValue) => (
                <Checkbox
                  style={{
                    display: "block",
                    padding: 5,
                    marginLeft: 0,
                    userSelect: "none",
                  }}
                  key={filterValue.id}
                  value={filterValue.id}
                  onChange={() => this.handleCheckboxChange(filterValue.id)}
                  checked={this.state.selectedCheckboxes?.includes(
                    filterValue.id
                  )}
                >
                  {filterValue.name}
                </Checkbox>
              ))}
          </div>
          <hr style={{ margin: "8px 0" }} /> {/* Horizontal line */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              onClick={this.handleCustomResetFilter}
              style={{ marginLeft: "8px" }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={this.handleCustomFilterConfirm}
            >
              OK
            </Button>
          </div>
        </div>
      ),
      filterIcon: (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center ",
          }}
          onClick={this.toggleFilter}
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="filter"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            style={{ fontSize: "12px", verticalAlign: "middle" }}
          >
            <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path>
          </svg>
        </div>
      ),
      filterDropdownVisible: this.state.filterVisible,
    },
    {
      title: "Clinic Org",
      width: "10%",

      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["hospital_name"]}
          </span>
        );
      },
    },

    {
      title: "Mobile Number",
      width: "15%",

      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["mobile"]}
          </span>
        );
      },
    },

    {
      title: "Email",
      width: "15%",

      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["email"]}
          </span>
        );
      },
    },

    // {
    //   title: "Clinical Org",
    //   width: "25%",
    //   render: (url, record) => {
    //     return (
    //       <span className={record.status == 0 ? "hide" : "show"}>
    //         {record["medical_group"]}
    //       </span>
    //     );
    //   },
    // },

    {
      title: "Action",
      key: "action",
      width: "13%",
      // ellipsis: true,
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                {record.status === 1 ? (
                  <Tooltip title="Edit">
                    <EditOutlined
                      id="table-edit"
                      style={{ margin: 14 }}
                      onClick={() =>
                        this.editBtnClick(
                          record.MPI,
                          record.user_id,
                          record.first_name,
                          record.last_name,
                          record.dob,
                          record.hospital_name,
                          record.email,
                          record.spec_id,
                          record.role_id,
                          record.about,
                          record.mobile,
                          record.experience,
                          record.image_url,
                          record.specialties,
                          "edit"
                        )
                      }
                    ></EditOutlined>
                  </Tooltip>
                ) : (
                  <EditOutlined style={{ margin: 14, opacity: 0.5 }} />
                )}
                {/* <div onClick={() => this.showDeleteConfirm(record.user_id)}>
                  <Link
                    to={{
                      pathname: "user-list",
                      state: { id: record.user_id, type: "delete" },
                    }}
                  >
                    <Tooltip title="Delete">
                      <DeleteOutlined id="table-delete" />
                    </Tooltip>
                  </Link>{" "}
                </div> */}
                <div
                  onClick={() =>
                    this.showDeleteConfirm(record.user_id, record.status)
                  }
                >
                  <Link
                    to={{
                      pathname: "user-list",
                      state: { id: record.user_id, type: "delete" },
                    }}
                  >
                    <Switch
                      style={{ margin: 10 }}
                      // loading={this.state.rowLoader}
                      checked={record.status === 0 ? false : true}
                    >
                      { }
                    </Switch>
                  </Link>
                </div>
              </li>
            }
          </span>
        );
      },
    },
  ];

  fetchOrganization = () => {
    ApiService.adddetails
      .getOrgList()
      .then((data) => {
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          this.setState({
            dataOrg: data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  getDoctorList() {
    if (!this.state.isFocusing) {
      var self = this;
      if (this.state.loadingFirstTime) {
        //this.setState({ isLoading: true });
      } else {
        this.setState({ paginationLoading: true });
      }
      let body = {
        page: this.state.currentPage,
        limit: this.state.itemsPerPage,
      };
      ApiService.adddetails
        .getCompanies(body)
        .then((data) => {
          // this.setState({ rowLoader: false });
          // // alert("hi")
          // if (data.data != null) {
          //   let resData = Array.from(data.data);
          //   let userId = [];
          //   let coll = {};
          //   let newData = [];
          //   for (let i = 0; i < resData.length; i++) {
          //     console.log(resData[i]["org_dtl"][0], "dataa");
          //     newData.push({
          //       MPI: resData[i]["MPI"],
          //       npi: resData[i]["npi"],
          //       first_name:
          //         resData[i]["first_name"].charAt(0).toUpperCase() +
          //         resData[i]["first_name"].slice(1),
          //       last_name:
          //         resData[i]["last_name"].charAt(0).toUpperCase() +
          //         resData[i]["last_name"].slice(1),
          //       dob: resData[i]["dob"],
          //       medical_group: resData[i]["medical_group"],
          //       hospital_name: resData[i]["hospital_name"],
          //       speciality: resData[i]["spec_master.name"],
          //       email: resData[i]["email"],
          //       password: resData[i]["password"],
          //       mobile: resData[i]["mobile"],
          //       user_id: resData[i]["user_id"],
          //       role_id: resData[i]["role_id"],
          //       specialties: resData[i]["specialties"],
          //       status: resData[i]["status"],
          //       experience:
          //         resData[i]["experience"] === ""
          //           ? 0
          //           : parseInt(resData[i]["experience"]),
          //       image_url: resData[i]["image_url"],
          //       spec_id: resData[i]["spec_id"],
          //       about: resData[i]["bio"],
          //       org_details: [resData[i]["org_details"]],
          //       organization:
          //         resData[i]["org_dtl"][0] === undefined
          //           ? ""
          //           : resData[i]["org_dtl"][0]["organization.name"],
          //     });
          //   }
          //   this.setState({ docData: newData, isLoading: true });
          //   /*
          //                               let name = this.state.track.first_name.filter(
          //                                   (track)=> {
          //                                       return track.first_name.indexOf(this.state.search)!== -1;
          //                                   }
          //                               );
          //                               /** */
          // } else {
          //   if (data.statusCode === 401) {
          //     this.props.history.push("/login");
          //   }
          //   self.setState({
          //     hasMoreItems: false,
          //     paginationLoading: false,
          //   });
          // }
          // console.log(this.state.docData, "doc");
          this.setState({ docData: data.data.rows });
          this.setState({ totalItems: data.data.count });
          //tweek1 above isLoading :true has been removed
        })
        .catch((error) => {
          self.setState({
            hasMoreItems: false,
          });
          this.setState({ paginationLoading: false });
          //above isLoading:false is removed
        });
    }
  }

  render() {
    const { docData, orgData, dataOrg } = this.state;

    let docTag = docData.sort(function (a, b) {
      return a.first_name?.localeCompare(b.first_name);
    });

    let orgTag = dataOrg.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let listOrgTag =
      orgTag.length > 0 &&
      orgTag.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">USERS LIST</h3>
          </Col>
        </Row>
        {/* <Row>
          {sessionStorage.getItem("rolename") === "Super Admin" && (
            <div style={{ width: "30%" }}>
              <Form>
                <Select
                  showSearch
                  labelInValue
                  style={{ width: "110%" }}
                  className="text-select"
                  placeholder="Select Organization"
                  optionFilterProp="children"
                  onChange={(e) => this.handleChange(e, "org")}
                >
                  {listOrgTag}
                </Select>
                <Button
                  className="filter-btn"
                  id=""
                  type="primary"
                  htmlType="submit"
                  onClick={() => this.getOrgDoctor()}
                  style={{ display: "flex", float: "left" }}
                >
                  Submit
                </Button>
              </Form>
            </div>
          )}
        </Row> */}
        <Row>
          <div className="sub-bar-other">
            {sessionStorage.getItem("rolename") === "Super Admin" && (
              <Search
                placeholder="Search user"
                //onSearch={(value) => console.log(value)}
                onChange={(value) => {
                  // Reset timer for user input
                  clearTimeout(this.state.searchTimer);
                  // Persist inputEvent for later use
                  value.persist()
                  // Keep track of latest query timestamp
                  this.setState({ lastUpdated: value.timeStamp })
                  // Pull data, but wait for 300ms to let user finish typing
                  this.setState({
                    searchTimer: setTimeout(() => {
                      // If this is the latest query && query.length > 0
                      if (value.timeStamp >= this.state.lastUpdated && value.target.value != "") {
                        const valueCopy = value.target.value;
                        this.setState({ isLoading: false, serarchPaginationFlag: true });
                        ApiService.general
                          .search_doc({
                            org_id: this.state.orgs_id,
                            name: valueCopy,
                          })
                          .then((res) => {
                            // For this block, by checking value.timeStamp > state.lastUpdated,
                            // we can know if there's any query from the user that comes after
                            // this query, and ensures this is the latest query so far.
                            // Sometimes preceding query takes longer time to run, so enter
                            // this block later, and that leads to data race issues.
                            if (value.timeStamp >= this.state.lastUpdated && res.data.length === 0 && valueCopy === "") {
                              this.setState({
                                isLoading: true,
                                serarchPaginationFlag: false,
                              });
                              this.fetchData();
                            } else if (value.timeStamp >= this.state.lastUpdated) {
                              this.setState({ isLoading: true, docData: res.data });
                              this.Tablecolumns();
                            }
                            // this.setView(res.data)
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      } else {
                        this.fetchData();
                        this.setState({
                          isLoading: true,
                          serarchPaginationFlag: false,
                        });
                      }

                    }, 300)
                  })
                }}
              />
            )}
            {sessionStorage.getItem("rolename") === "Admin" && (
              <Search
                placeholder="Search user"
                //onSearch={(value) => console.log(value)}
                onChange={(value) => {
                  // Reset timer for user input
                  clearTimeout(this.state.searchTimer);
                  // Persist inputEvent for later use
                  value.persist()
                  // Keep track of latest query timestamp
                  this.setState({ lastUpdated: value.timeStamp })
                  // Pull data, but wait for 300ms to let user finish typing
                  this.setState({
                    searchTimer: setTimeout(() => {
                      // If this is the latest query && query.length > 0
                      if (value.timeStamp >= this.state.lastUpdated && value.target.value != "") {
                        const valueCopy = value.target.value;
                        this.setState({ isLoading: false, serarchPaginationFlag: true });
                        ApiService.general
                          .search_doc_for_org({
                            org_id: sessionStorage.getItem("org_id"),
                            name: valueCopy,
                          })
                          .then((res) => {
                            // For this block, by checking value.timeStamp > state.lastUpdated,
                            // we can know if there's any query from the user that comes after
                            // this query, and ensures this is the latest query so far.
                            // Sometimes preceding query takes longer time to run, so enter
                            // this block later, and that leads to data race issues.
                            if (value.timeStamp >= this.state.lastUpdated && res.data.length === 0 && valueCopy === "") {
                              this.setState({
                                isLoading: true,
                                serarchPaginationFlag: false,
                              });
                              this.fetchData();
                            } else if (value.timeStamp >= this.state.lastUpdated) {
                              this.setState({ isLoading: true, docData: res.data });
                              this.Tablecolumns();
                            }
                            // this.setView(res.data)
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      } else {
                        this.fetchData();
                        this.setState({
                          isLoading: true,
                          serarchPaginationFlag: false,
                        });
                      }
                    }, 300)
                  })
                }}
              />
            )}
            <Link to={{ pathname: "/add-user" }}>
              <Button className="adduser-btn" id="" type="primary">
                Add User
              </Button>
            </Link>
            {/* <Link to={{ pathname: '' }}>
            <Button type='file' className='btn-type' id='' loading={!this.state.isLoading}  >
                    Bulk Upload
            </Button>
            </Link> 
            <Upload >
                <Button className='adduser-btn'>Upload</Button>
            </Upload>,*/}
            <ReactFileReader handleFiles={this.handleFiles} fileTypes={".csv"}>
              <Button className="adduser-btn" type="primary">
                Bulk Upload
              </Button>
            </ReactFileReader>
            <a href={require("../../assets/files/Users.csv")}>
              <Button className="btn-type" id="" type="primary">
                Bulk Template
              </Button>
            </a>
            {/* <a href={require("../../assets/files/User.csv")}  className='adduser-btn' download="myFile">Download</a> */}

            {/* search box for doctors*/}
          </div>
        </Row>

        <div>
          <Row>
            <Col span={24}>
              <div class="fill-table">
                <Table
                  columns={this.Tablecolumns()}
                  //responsive={true}
                  //rowKey={record => record.id} not my
                  dataSource={this.state.docData} //new assigned from api
                  pagination={this.state.serarchPaginationFlag}
                  loading={!this.state.isLoading}

                //  onChange={this.handleTableChange}
                />

                {/* <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 8 }}>Records per Page:</span>
                  <Select
                    value={this.state.itemsPerPage}
                    onChange={this.handleRecordsPerPageChange}
                    style={{ width: "auto" }}
                  >
                    {this.state.recordsPerPageOptions.map((option) => (
                      <Option key={option} value={option}>
                        {option + " / page"}
                      </Option>
                    ))}
                  </Select>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 16,
                  }}
                >
                  {!this.state.serarchPaginationFlag && (
                    <Pagination
                      current={this.state.currentPage}
                      total={this.state.totalItems}
                      pageSize={this.state.itemsPerPage}
                      onChange={this.handlePageChange}
                      showSizeChanger
                      onShowSizeChange={this.handleSizeChange}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
