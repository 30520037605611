import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Avatar,
  Spin,
  Button,
  Input,
  message,
  Form,
  Select,
  Checkbox,
  Modal,
  Radio,
  Table,
  Switch,
  Tooltip,
} from "antd";

import { Container } from "reactstrap";
import {
  PlusOutlined,
  MinusOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  FullscreenExitOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "../Admin/admin.css";
import "./organization.css";
import { Link } from "react-router-dom";
import ApiService from "../../services/api-services";
import firebase from "../../services/firebase"; //for uploading image
// import $, { $window } from "jquery";

// import { isLoggedIn } from '../../helpers/utility';

import TextArea from "antd/lib/input/TextArea";
import UserContext from "../../UserContext";
import { render } from "react-dom";
import ReactDragListView from "react-drag-listview";
import Search from "antd/lib/input/Search";
const { Option } = Select;
const { confirm } = Modal;

const FormItem = Form.Item;

const StaffProvider = ({ options, user_id, getValues, doSorting, org_id }) => {
  const [loading, setload] = useState(true);
  const [values, setvalues] = useState([]);

  useEffect(() => {
    if (loading) {
      ApiService.general
        .provider_staff_map_view({
          provider_id: user_id,
          org_id: org_id,
        })
        .then((res) => {
          let val = [];
          res &&
            res.data.map((item) => {
              val.push(item.user_id);
            });

          setvalues(val);
          setload(false);
        });
    }
  }, [loading, org_id, user_id, values]);
  const getContent = () => {
    console.log(values, options, "values");
    return (
      <Checkbox.Group
        options={doSorting(options)}
        onChange={(ev) => {
          getValues("staff", ev);
          setvalues(ev);
        }}
        checkboxid={user_id}
        value={values}
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
        }}
      ></Checkbox.Group>
    );
  };
  return loading ? "loading" : getContent();
};

const VariableConfig = ({
  options,
  id,
  name,
  getValues,
  doSorting,
  org_id,
}) => {
  const [loading, setload] = useState(true);
  const [values, setvalues] = useState([]);

  useEffect(() => {
    if (loading) {
      ApiService.general
        .get_org_activity_maps({
          activity_id: id,
          org_id: org_id,
        })
        .then(async (res) => {
          //console.log(res.data,'res')
          let val = [];
          if (res && Array.isArray(res.data) == false) {
            res.data.roles.map((item) => {
              val.push(item);
            });
          }

          // res != [] && res.data.roles.map(item => {
          //     console.log(item,'iiii')

          //     val.push(item.role_id)
          // })

          setvalues(val);
          setload(false);
        });
    }
  }, [loading, org_id, id, values]);
  const getContent = () => {
    console.log(values, options, "values");
    return (
      <Checkbox.Group
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
        options={doSorting(options)}
        onChange={(ev) => {
          console.log(ev, "eeeee");
          getValues("variable", ev);
          setvalues(ev);
        }}
        checkboxid={id}
        value={values}
      ></Checkbox.Group>
    );
  };
  return loading ? "loading" : getContent();
};

/*Profile Upload comp-----Startss */
const ProfileUpload = ({ image_url, userId, orgId }) => {
  const filetypes = ["image/jpeg", "image/png", "image/gif"];
  const [load, setload] = useState(null);
  const [imagePreview, setimagePreview] = useState(image_url);
  const [active, setactive] = useState("edit");

  /* Editing Profile pictuew--Starts */
  const ImgUpload = ({ onChange, src }) => {
    return (
      <label htmlFor="photo-upload" className="custom-file-upload fas">
        {src ? (
          <Spin spinning={load}>
            <img className="image-style" src={src} />
          </Spin>
        ) : (
          <Avatar
            shape="square"
            size="large"
            icon={<UserOutlined />}
            style={{
              borderRadius: "10%",
              position: "relative",
              height: 100,
              width: 100,
              padding: 23,
            }}
          />
        )}
        <input id="photo-upload" type="file" onChange={onChange} />
      </label>
    );
  };
  function SetUserprofileLocally(url) {
    sessionStorage.setItem("orgProfile", url);
    message.success("Logo uploaded Successfully");
  }
  function uploadImage(url, orgId, file) {
    const imgUrl = URL.createObjectURL(file);
    fetch(imgUrl)
      .then((r) => r.blob())
      .then((res) => {
        var metadata = {
          contentType: "image/jpeg",
          cacheControl: "public,max-age=300",
        };

        const ref = firebase.storage().ref("/profile_url/" + orgId);
        console.log(ref, "ref");
        const uploadTask = ref.put(res, metadata);

        const handleState = (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              setload(true);
              console.log("Upload is running");
              break;
          }
        };
        const handleError = (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
            // User canceled the upload
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        };
        const handleSuccess = () => {
          setload(false);
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL, "downloadURL");
            SetUserprofileLocally(downloadURL);
          });
        };
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          handleState,
          handleError,
          handleSuccess
        );
      });
  }
  const photoUpload = (e) => {
    console.log(e, "upload");
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!filetypes.includes(file.type)) {
      message.error("Upload valid image only png and jpeg are allowed");
      return;
    }

    if (file.size >= 1000000) {
      alert("file size is too large");
      return;
    }

    console.log(file, "src");
    reader.onloadend = () => {
      uploadImage(reader.result, orgId, file);
      setimagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const Edit = ({ onSubmit, children }) => {
    return (
      <div className="card">
        <form onSubmit={onSubmit}>
          <div className="profile-card">
            <h1>Org Profile</h1>
            {children}
          </div>
        </form>
      </div>
    );
  };
  /* Editing Profile pictuew--Endss */

  const handleSubmit = (e) => {
    e.preventDefault();
    let activeP = active === "edit" ? "profile" : "edit";
    setactive(activeP);
  };

  return (
    <div>
      <Edit onSubmit={handleSubmit}>
        <ImgUpload onChange={photoUpload} src={imagePreview} />
      </Edit>
    </div>
  );
};
/*Profile Upload comp-----Endss */

export default class AddDoctor extends Component {
  static contextType = UserContext;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    var type,
      formData = {};
    if (
      this.props.location.state !== undefined &&
      sessionStorage.getItem("rolename") !== "Admin"
    ) {
      formData = {
        org_id: this.props.location.state.org_id,
        org_npi: this.props.location.state.org_npi,
        name: this.props.location.state.name,
        address: this.props.location.state.address,
        org_type: this.props.location.state.org_type,
        primary_contact: this.props.location.state.primary_contact,
        secondary_contact: this.props.location.state.secondary_contact,
        address_obj: this.props.location.state.address_obj, //adress_obj bug fix
        image_url:
          this.props.location.state.image_url === null
            ? ""
            : this.props.location.state.image_url, //for uploading image
        roles: this.props.location.state.response,
        type: this.props.location.state.type,
        speciality: this.props.location.state.speciality || [],
        close_codes: this.props.location.state.close_codes_master,
        speciality: this.props.location.state.speciality_master,
        permission: this.props.location.state.permission_master,
        video_call: this.props.location.state.video_call,
        AI: this.props.location.state.AI,
        doc_to_doc: this.props.location.state.doc_to_doc,
        consumer_to_doc: this.props.location.state.consumer_to_doc,
        max_qstn: this.props.location.state.max_qstn,
        min_qstn: this.props.location.state.min_qstn,
        respond_overdue: this.props.location.state.respond_overdue,
        active_chat_closure: this.props.location.state.active_chat_closure,
        trigger_email: this.props.location.state.trigger_email,
        trigger_sms: this.props.location.state.trigger_sms,
        mod_yes: this.props.location.state.mod_yes,
        mod_no: this.props.location.state.mod_no,
        mod_maybe: this.props.location.state.mod_maybe,
        app_name: this.props.location.state.app_name,
        app_url: this.props.location.state.app_url,
        white_label: this.props.location.state.white_label,
        photo: this.props.location.state.photo,
        camera: this.props.location.state.camera,
        attachment: this.props.location.state.attachment,
        video: this.props.location.state.video,
        audio: this.props.location.state.audio,
        GCP_record_video: this.props.location.state.GCP_record_video,
        is_email_generated: this.props.location.state.is_email_generated,
        is_icd_present: this.props.location.state.is_icd_present,
        is_cpt_present: this.props.location.state.is_cpt_present,
        credit_card: this.props.location.state.credit_card,
        is_email_hubchat_summary:
          this.props.location.state.is_email_hubchat_summary,
        is_email_close_code: this.props.location.state.is_email_close_code,
        is_email_participant_access:
          this.props.location.state.is_email_participant_access,
        is_email_initial_welcome_notification:
          this.props.location.state.is_email_initial_welcome_notification,
        is_sms_hubchat_summary:
          this.props.location.state.is_sms_hubchat_summary,
        is_sms_close_code: this.props.location.state.is_sms_close_code,
        is_sms_participant_access:
          this.props.location.state.is_sms_participant_access,
        is_sms_initial_welcome_notification:
          this.props.location.state.is_sms_initial_welcome_notification,
        auto_assign: this.props.location.state.auto_assign,
        session: this.props.location.state.session,
        unresponded_email: this.props.location.state.unresponded_email,
        unresponded_sms: this.props.location.state.unresponded_sms,

        /*Field allowed in viewpatient screen*/
        is_first_name: this.props.location.state.is_first_name,
        is_middle_name: this.props.location.state.is_middle_name,
        is_last_name: this.props.location.state.is_last_name,
        is_dob: this.props.location.state.is_dob,
        is_address: this.props.location.state.is_address,
        is_city: this.props.location.state.is_city,
        is_state: this.props.location.state.is_state,
        is_zip_code: this.props.location.state.is_zip_code,
        is_mobile: this.props.location.state.is_mobile,
        is_sex: this.props.location.state.is_sex,
        is_email: this.props.location.state.is_email,
        is_mrn: this.props.location.state.is_mrn,
        is_home_phone: this.props.location.state.is_home_phone,
        is_employer: this.props.location.state.is_employer,
        is_insurence: this.props.location.state.is_insurence,
        is_country: this.props.location.state.is_country,
        is_member_id: this.props.location.state.is_member_id,
        clinical_qtn_info: this.props.location.state.clinical_qtn_info,
        sub_groups: this.props.location.state.sub_groups,
      };
    } else if (sessionStorage.getItem("rolename") === "Admin") {
      formData = {
        org_id: sessionStorage.getItem("org_id"),
        type: "edit",
      };
    } else {
      formData = {
        type: "add",
      };
    }
    this.state = {
      buttonName: "",
      button: "",
      button1: "",
      button2: "",
      button3: "",
      button4: "",
      title: [],
      description: [],
      isLoading: false,
      rowLoader: false,
      id: "",
      tagOrg: [],
      tagRole: [],
      tagRoles: [],
      specialityTagRoles: [],
      roleItem: [],
      values: [],
      role: "",
      check: false,
      checkdisable: false,
      //response: [],
      formType: type,
      formData: formData,
      speciality: [],
      isFetchSpeciality: false,
      SpecialityFullyLoaded: false,
      SpeclData: [],
      closecodeFullyLoaded: false,
      codeData: [],
      isFetchCode: false,
      viewData: [],
      dataOrg: [],
      viewData1: [],
      permData: [],
      subgroupData: [],
      specData: [],
      loading: false,
      visible: false,
      visiblestaff: false,
      visiblehelp: false,
      visiblevariable: false,
      visibletimeframe: false,
      visibletimeframeedit: false,
      modalVisible: false,
      modalGroupVisible: false,
      checkedValues: [],
      time_frame_id: "",
      time_frame_name: "",
      timeframeName: "",
      sub_groups: [],
      subgroupName: "",
      value: 0,
      closecode: [],
      speciality1: [],
      permission: [],
      timeframes: [],
      staff: [],
      message: [],
      provider: [],
      accepted: false,
      video_call: 1,
      AI: 2,
      doc_to_doc: 3,
      consumer_to_doc: 4,
      maxvalue: "",
      minvalue: "",
      modyesvalue: "",
      modnovalue: "",
      modmaybevalue: "",
      appname: "",
      appurl: "",
      photo: 1,
      camera: 2,
      attachment: 3,
      video: 4,
      audio: 5,
      GCP_record_video: 6,
      is_email_generated: 6,
      is_icd_present: 7,
      is_cpt_present: 8,

      /*Fields Allowed in view patient */
      is_first_name: 9,
      is_middle_name: 10,
      is_last_name: 11,
      is_dob: 12,
      is_address: 13,
      is_city: 14,
      is_state: 15,
      is_zip_code: 16,
      is_mobile: 17,
      is_sex: 18,
      is_email: 19,
      is_mrn: 20,
      is_home_phone: 21,
      is_employer: 22,
      is_insurence: 23,
      is_country: 24,
      is_member_id: 25,
      clinical_qtn_info: "",

      errors: {},
      name: "",
      title1: [],
      values1: [],
      help: [],
      // filterProvider
      filteredProvider: [],
    };
  }

  // handleChange = (e) => {
  //     if (["name", "age"].includes(e.target.className) ) {
  //       let cats = [...this.state.cats]
  //       cats[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase()
  //       this.setState({ cats }, () => console.log(this.state.cats))
  //     } else {
  //       this.setState({ [e.target.name]: e.target.value.toUpperCase() })
  //     }
  // }

  // addCat = (e) => {
  //     this.setState((prevState) => ({
  //       cats: [...prevState.cats, {name:"", age:""}],
  //     }));
  //   }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showStaffModal = () => {
    this.setState({
      visiblestaff: true,
    });
  };

  helpModal = () => {
    this.setState({
      visiblehelp: true,
    });
  };

  variableModal = () => {
    this.setState({
      visiblevariable: true,
    });
  };

  timeFrameModal = () => {
    this.setState({
      visibletimeframe: true,
    });
  };

  timeFrameEditModal = (item, id, name) => {
    this.setState((prev) => {
      return {
        time_frame_id: id ? id : item,
        time_frame_name: name,
        visibletimeframeedit: !prev.visibletimeframeedit,
      };
    });
    // this.setState({
    //   visibletimeframeedit: true,
    // });
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  setModalGroupVisible = (args) => this.setState({ modalGroupVisible: args });

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleOk1 = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        loading: false,
        visiblestaff: false,
        visiblevariable: false,
      });
    }, 3000);
  };

  handleCancel1 = () => {
    this.setState({ visiblestaff: false, visiblevariable: false });
  };

  handleOk4 = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        loading: false,
        visiblestaff: false,
        visibletimeframe: false,
      });
    }, 3000);
  };

  handleCancel4 = () => {
    this.setState({ visiblestaff: false, visibletimeframe: false });
  };

  handleOk2 = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visiblehelp: false });
    }, 3000);
  };

  handleCancel2 = () => {
    this.setState({ visiblehelp: false });
  };

  onChange = (e) => {
    const { formData, value } = this.state;
    console.log("radio checked", e.target.value);
    this.setState({
      value: e.target.value,
    });
    this.setState({
      formData: { ...formData, white_label: e.target.value },
    });
    if (this.state.value === 1) {
      //alert("hii")
      this.setState({ appname: "" });
      this.setState({ appurl: "" });
      this.setState({
        formData: { ...formData, app_name: "" },
      });
      this.setState({
        formData: { ...formData, app_url: "" },
      });
    }
  };

  componentDidMount() {
    this.getRoleList();
    this.getRoleLists();
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }

    var bodysFormData = new FormData();
    let bodys = {
      org_id: this.state.formData.org_id,
    };
    this.getRoleList();
    ApiService.adddetails.viewOrg(bodys).then((data) => {
      console.log(data.data, "close1");
      if (data.status === 200) {
        let newData = data.data;
        Object.keys(newData).map((key) => {
          if (!newData[key]) {
            // newData[key] = ''
          }
          if (key === "close_codes" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "permission" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "speciality" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "size" && !newData[key]) {
            newData[key] = 4;
          }
        });

        this.setState({ SpecialityFullyLoaded: true, viewData: newData });
        this.setState({ name: newData["name"] });

        // this.setState({ SpecialityFullyLoaded: true, name: data.data['spec_id'] })
      }
      console.log(this.state.viewData, "original");
    });

    this.getOrganizationType();
    // this.getOrganizationList();
    this.getspeciality();
    this.getStaff();
    this.getProvider();
    this.getVariables();

    // console.log("ash",this.state.formData.type)
    if (this.state.formData.type === "edit") {
      this.setState({ buttonName: "Update" });
      sessionStorage.getItem("rolename") === "Super Admin" &&
        this.setState({ button: "Configuration" });

      this.setState({
        button1: "Staff",
        button2: "Help",
        button3: "Variable",
        button4: "TimeFrames",
      });
    } else if (this.state.formData.type === "delete") {
      this.setState({ buttonName: "Delete" });
    } else if (this.state.formData.type === "view") {
      //   this.setState({ buttonName: 'Configuration' });
      this.setState({ button: "Configuration" });
    } else {
      this.setState({ buttonName: "Save" });
    }

    message.config({ top: 150, duration: 2 });
  }

  getAliasByRoleId(roleId) {
    console.log("Testing..", roleId);
    let data = this.state.viewData.provider_alias;
    console.log("Testing2", this.state.viewData.provider_alias);
    let temp = "";
    data
      ? data.map((item) => {
          if (item.role_id === roleId) {
            temp = item.role_alias;
          }
        })
      : (temp = "");
    return temp;
  }
  getRoleList() {
    ApiService.adddetails.getRoleType().then((data) => {
      if (data.status === 200) {
        let roles = [];
        data.data.response.map((item) => {
          console.log("ill ida kalla", item);
          roles.push({
            ...item,
            isVissible: false,
            provider_alias_input: this.getAliasByRoleId(item.role_id),
          });
        });
        console.log("tagOrg", data);
        this.setState({ tagRole: roles });
      }
      console.log(this.state.tagRole, "yenta mare");
    });
  }

  getRoleLists() {
    ApiService.adddetails.getRoleType().then((data) => {
      if (data.status === 200) {
        let roleData = data.data.response.filter(
          (item) =>
            item.role_name === "Responder Afya" ||
            item.role_name === "Submitter Provider" ||
            item.role_name === "Responder Provider" ||
            item.role_name === "Responder Staff" ||
            item.role_name === "Submitter Staff"
        );
        this.setState({ tagRoles: roleData });
      }
      //prod revert
      //uncomment below code for new speciality

      // if (data.status === 200) {
      //   let roleData = data.data.response.filter(
      //     (item) =>
      //       item.role_name === "Submitter Provider-New Chat" ||
      //       item.role_name === "Submitter Staff-New Chat" ||
      //       item.role_name === "Submitter Provider-@ Specialty" ||
      //       item.role_name === "Responder Provider - @ Specialty" ||
      //       item.role_name === "Submitter Staff-@ Specialty" ||
      //       item.role_name === "Staff Responder - @ Specialty"
      //   );
      //   this.setState({ specialityTagRoles: roleData });
      // }
    });
  }

  /*Function for clear text field  */
  clearData() {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        name: "",
        org_type: "",
        primary_contact: "",
        secondary_contact: "",
        role_id: "",
        speciality: "",
        address: "",
        Address_suite: "",
      },
      error: "",
    });
    this.props.history.push("/organization-list");
    {
      sessionStorage.getItem("rolename") === "Admin" &&
        this.props.history.push("/add-orgn");
    }
  }

  /*Api for save company  */
  doSave() {
    //alert('dosave')
    var bodyFormData = new FormData();
    this.setState({ isLoading: true });
    let address = {
      address_suite: this.state.viewData.address_suite
        ? this.state.viewData.address_suite
        : "",
      city: this.state.viewData.city,
      state: this.state.viewData.state,
      zipcode: this.state.viewData.zipcode,
    };

    let body = {
      //"org_npi": this.state.viewData.org_npi ? this.state.viewData.org_npi : '',
      name:
        this.state.viewData.name.charAt(0).toUpperCase() +
        this.state.viewData.name.slice(1),
      org_id: this.state.viewData.org_id,
      org_type: this.state.viewData.org_type,
      primary_phone: this.state.viewData.primary_contact
        ? this.state.viewData.primary_contact
        : "",
      image_url: sessionStorage.getItem("orgProfile")
        ? sessionStorage.getItem("orgProfile")
        : "", //for uploading image
      size: 4,
      secondary_phone: this.state.viewData.secondary_contact
        ? this.state.viewData.secondary_contact
        : "",
      address: this.state.viewData.address,
      address_obj: address,
      speciality: [],
    };
    console.log(JSON.stringify(body), "body");
    // debugger
    ApiService.adddetails
      .addOrg(body)
      .then((data) => {
        console.log(data, "dd");
        this.setState({ isLoading: false });
        if (data.status === 200) {
          sessionStorage.removeItem("orgProfile");
          this.props.history.push("/organization-list");
          message.success("Successfully added the Organization");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.status === 702) {
          message.error("Organization already exist");
        }
      });
  }

  getspeciality() {
    let body = {
      dob: "",
    };
    ApiService.adddetails.getSplType(body).then((data) => {
      if (data.status === 200) {
        // console.log("tagOrg",data)
        const newData =
          (data.data && data.data.filter((x) => x.status !== 0)) || [];

        this.setState({ speciality: newData });
      }
    });
  }

  showTimeframe(status) {
    console.log(status, "status");
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "the org wide time frames?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveTimeframeOrg(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showSmsNotification(status) {
    console.log(status, "status");
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "the consumer sms notification?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveSmsOrg(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showEmailNotification(status) {
    console.log(status, "status");
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "the consumer Email notification?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveEmailOrg(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showVrpchat(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "the VRP to Afya chat modularity?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveVrpChat(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showConsumerSubmitterUnrespondedSms(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "Consumer Unresponded SMS Notification?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveConsumerNotificationUnrespondedSms(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showConsumerSubmitterUnrespondedEmail(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "Consumer Unresponded Email Notification?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveConsumerNotificationUnrespondedEmail(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showSmsBidirectionalConfirmation(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Disable" : "Enable"}` +
        " " +
        "Bidirectional SMS?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveSmsBidirectional(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showPrivateChatSubChatConfirmation(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Disable" : "Enable"}` +
        " " +
        "Private conversation / Sub conversation?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSavePrivateChatSubChat(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  showSameSpecialityConfirmation(status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Disable" : "Enable"}` +
        " " +
        "Same Specialty?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.updateSaveSameSpeciality(status);
        //alert("activate");
        //that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  async updateSaveTimeframeOrg(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.timeframe_orgwide = status === 0 ? 1 : 0;
    body.timeframe_appwide = status === 0 ? 0 : 1;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSaveSmsOrg(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.consumer_sms_notification = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          const payload = {
            org_id: this.state.viewData.org_id,
            type: "sms",
            action: status === 0 ? 1 : 0,
          };
          ApiService.adddetails
            .editOrgWideConsumerNotification(payload)
            .then((orgWideConsumerNotificationStatus) => {
              //  alert("edit api")
              this.setState({ isLoading: false });
              if (orgWideConsumerNotificationStatus.status === 200) {
                // const orgWideConsumerNotificationStatus = await ApiService.adddetails.editOrgWideConsumerNotification(payload);
                //   console.log(data)
                sessionStorage.removeItem("orgProfile");
                message.success("Successfully updated the Organization");
                this.props.history.push("/organization-list");
                {
                  sessionStorage.getItem("rolename") === "Admin" &&
                    this.props.history.push("/add-orgn");
                }
              } else if (data.statusCode === 500) {
                message.error("Organization already exist");
              } else if (data.statusCode === 401) {
                this.props.history.push("/login");
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });

              if (error.data.statusCode === 500) {
                message.error("Organization already exist");
              }
            });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong, try again");
      });
  }

  async updateSaveEmailOrg(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.consumer_email_notification = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          const payload = {
            org_id: this.state.viewData.org_id,
            type: "email",
            action: status === 0 ? 1 : 0,
          };
          ApiService.adddetails
            .editOrgWideConsumerNotification(payload)
            .then((orgWideConsumerNotificationStatus) => {
              //  alert("edit api")
              this.setState({ isLoading: false });
              if (orgWideConsumerNotificationStatus.status === 200) {
                // const orgWideConsumerNotificationStatus = await ApiService.adddetails.editOrgWideConsumerNotification(payload);
                //   console.log(data)
                sessionStorage.removeItem("orgProfile");
                message.success("Successfully updated the Organization");
                this.props.history.push("/organization-list");
                {
                  sessionStorage.getItem("rolename") === "Admin" &&
                    this.props.history.push("/add-orgn");
                }
              } else if (data.statusCode === 500) {
                message.error("Organization already exist");
              } else if (data.statusCode === 401) {
                this.props.history.push("/login");
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });

              if (error.data.statusCode === 500) {
                message.error("Organization already exist");
              }
            });
        }
      })
      .catch((error) => {
        message.error("Something went wrong, try again!");
      });
  }

  async updateSaveVrpChat(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.vrp_afya_chat_modularity = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSaveConsumerNotificationUnrespondedEmail(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.consumer_email_notification_unresponded = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSaveConsumerNotificationUnrespondedSms(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.consumer_sms_notification_unresponded = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSaveSmsBidirectional(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.is_bidirectional = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSavePrivateChatSubChat(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.is_private_chat = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }
  async updateSaveSameSpeciality(status) {
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.at_same_spec = status === 0 ? 1 : 0;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  async updateSaveEmailAlertOfProviderResponder(status) {
    const viewData = this.state.viewData;
    this.setState({
      viewData: { ...viewData, is_sr_email: Number(!status) },
    });
    // let address = {
    //   address_suite:
    //     this.state.viewData.address_suite ||
    //     this.state.viewData.address_obj.address_suite,
    //   city: this.state.viewData.city || this.state.viewData.address_obj.city,
    //   state: this.state.viewData.state || this.state.viewData.address_obj.state,
    //   zipcode:
    //     this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    // };
    // console.log(address, "view");
    // this.setState({ isLoading: true });
    // const body = Object.assign({}, this.state.viewData);
    // delete body.close_codes_master;
    // delete body.createdAt;
    // delete body.speciality_master;
    // delete body.updatedAt;
    // delete body.status;
    // delete body.permission_master;
    // const provider_alias = await this.getProvaideralias();
    // body.clinical_qtn_info =
    //   this.state.viewData.clinical_qtn_info === null
    //     ? ""
    //     : this.state.viewData.clinical_qtn_info;
    // body.provider_alias = provider_alias;
    // body.AI_spec = [""];
    // body.address_obj = address;
    // body.is_sr_email = status === 0 ? 1 : 0;
    // body.timeframes = [];
    // body.sub_groups = [];
    // body.image_url = sessionStorage.getItem("orgProfile")
    //   ? sessionStorage.getItem("orgProfile")
    //   : this.state.formData.image_url; //for updateing image
    // Object.keys(body).map((keys) => {
    //   if (body[keys] === null) {
    //     body[keys] = 0;
    //   }
    //   if (keys === "app_url" && body[keys] === 0) {
    //     body[keys] = "";
    //   }
    //   if (keys === "app_name" && body[keys] === 0) {
    //     body[keys] = "";
    //   }
    // });
    // ApiService.adddetails
    //   .editOrg(body)
    //   .then((data) => {
    //     //  alert("edit api")
    //     this.setState({ isLoading: false });
    //     if (data.status === 200) {
    //       //   console.log(data)
    //       sessionStorage.removeItem("orgProfile");
    //       message.success("Successfully updated the Organization");
    //       this.props.history.push("/organization-list");
    //       {
    //         sessionStorage.getItem("rolename") === "Admin" &&
    //           this.props.history.push("/add-orgn");
    //       }
    //     } else if (data.statusCode === 500) {
    //       message.error("Organization already exist");
    //     } else if (data.statusCode === 401) {
    //       this.props.history.push("/login");
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ isLoading: false });
    //     if (error.data.statusCode === 500) {
    //       message.error("Organization already exist");
    //     }
    //   });
  }

  async updateSaveGenerateTemporaryPasswordConfig(status) {
    this.setState({
      viewData: {
        ...this.state.viewData,
        temp_pswd_config: {
          ...this.state.viewData.temp_pswd_config,
          is_temp_pswd_config: Number(!status),
        },
      },
    });
  }

  async handleTemporaryPasswordRolesCheckbox(status, rolePassed) {
    this.setState({
      viewData: {
        ...this.state.viewData,
        temp_pswd_config: {
          ...this.state.viewData.temp_pswd_config,
          [rolePassed]: Number(!status),
        },
      },
    });
  }

  /*Api for company type */
  getOrganizationType() {
    ApiService.adddetails.getOrgType().then((data) => {
      if (data.status === 200) {
        // console.log("tagOrg",data)
        this.setState({ tagOrg: data.data });
      }
    });
  }

  async updateSave() {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    if (
      this.state.viewData.is_sr_email &&
      !emailRegex.test(this.state.viewData.sr_email)
    ) {
      // Invalid email entered
      message.error("Enter valid SR email");
      return;
      // ... handle the invalid email case
    }
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    console.log(address, "view");
    this.setState({ isLoading: true });
    const body = Object.assign({}, this.state.viewData);

    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;

    const provider_alias = await this.getProvaideralias();
    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;

    body.AI_spec = [""];
    body.address_obj = address;
    body.timeframes = [];
    body.sub_groups = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image

    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });

    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        //  alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //   console.log(data)
          sessionStorage.removeItem("orgProfile");
          message.success("Successfully updated the Organization");
          this.props.history.push("/organization-list");
          {
            sessionStorage.getItem("rolename") === "Admin" &&
              this.props.history.push("/add-orgn");
          }
        } else if (data.statusCode === 500) {
          message.error("Organization already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Organization already exist");
        }
      });
  }

  // AddMoreOrgFeild = async () => {
  //     const val = await this.formRef.current.validateFields()
  //     this.setState(pr => {
  //         const { tagRole, tagOrg, formData, } = this.state
  //         const type = formData.type;
  //         const data = formData.org_details;
  //         let orgFileds = pr.orgFileds
  //         let newfiled = Array.from(new Set([this.addOrg('add', data, tagRole, tagOrg)]))
  //         orgFileds = [...orgFileds, ...newfiled]

  //         return {
  //             ...pr,
  //             orgFileds,
  //         }

  //     })
  // }

  validateForm() {
    // alert('asda')
    const data = this.state.formData;
    console.log("f", data);
    var regexp = new RegExp(/^[a-zA-Z0-9 ]+$/);

    if (data === "") {
      // message.warn("Please fill the details");
    } else {
      if (data.name === "") {
        message.warn("Please fill the details");
      } else {
        if (this.state.buttonName === "Update") {
          this.updateSave();
        } else if (this.state.buttonName === "Save") {
          //alert('save')
          this.doSave(); //change this
        } else {
          // this.deleteCompany();
        }
      }
    }
  }

  addTimeFrame() {
    const { timeframeName } = this.state;
    let name = this.state.timeframeName;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    console.log(patt, "patt");
    if (name === "" || patt === false) {
      message.error("Please Enter valid details ");
      return;
    }

    let body = {
      type: "orgwide_add",
      name:
        this.state.timeframeName.charAt(0).toUpperCase() +
        this.state.timeframeName.slice(1),
      org_id: this.state.formData.org_id,
    };
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the Time frame");
          this.setState({ modalVisible: false });
          this.getVariables();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  updateTimeFrameSave() {
    // alert('dosave')

    const { time_frame_name, time_frame_id } = this.state;
    let name = this.state.time_frame_name;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    console.log(patt, "patt");
    if (name === "" || patt === false) {
      message.error("Please Enter valid details ");
      return;
    }
    // if (
    //   this.state.formData.org_id !== "" ||
    //   this.state.formData.org_id !== null
    // ) {
    let body = {
      type: "orgwide_edit",
      name:
        this.state.time_frame_name.charAt(0).toUpperCase() +
        this.state.time_frame_name.slice(1),
      org_id: this.state.formData.org_id,
      tf_id: this.state.time_frame_id,
    };
    // }
    //console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully updated the Time frame");
          this.setState({ visibletimeframeedit: false });
          this.getVariables();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("time frame already exist");
        // }
      });
  }

  handleChange = (e, field) => {
    // alert('handle')
    const { formData, submitted } = this.state;

    const reg = /^[+-]?(0|[1-9][0-9]*)(\.[0-9]*)?$/;

    if (field === "primary_contact" || field === "secondary_contact") {
      console.log(e);
      if (e.length > 10) {
        return false;
      }

      // if ((!Number.isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-' || e.target.value === '+') {
      //    let { value, min, max } = e.target;

      //this.setState({ formData: { ...formData, [field]: value }, error: '' })
      //  }
    }
    if (field === "speciality") {
      // alert('hii')
      this.setState({
        formData: { ...formData, [field]: e },
      });
      return;
    }

    if (field === "name") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "primary_contact") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "secondary_contact") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "address") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "org_type") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {
          console.log();
        }
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  /* sign out */
  signOut() {
    const { setLoggin } = this.context;
    setLoggin("false");
    this.props.history.push("/login");
    sessionStorage.setItem("isLoggedIn", false);
  }

  showDeleteConfirm(id, status) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "this timeframe?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteTimeframe(id, status);
      },
      onCancel() {},
    });
  }

  deleteTimeframe(id, status) {
    let body = {
      type: "org_delete",
      tf_id: id,
      status: status === 0 ? 1 : 0,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          // message.success("Successfully deleted the timeframe");
          this.setState({ visible: false });
          this.getVariables();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  showDeleteModal(id, tfid) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title: "Are you sure want to delete" + " " + "this timeframe?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteTimeframes(id, tfid);
      },
      onCancel() {},
    });
  }

  deleteTimeframes(id, tfid) {
    let body = {
      type: "org_hard_delete",
      tf_id: id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully deleted the timeframe");
          this.setState({ visible: false });
          this.getVariables();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  handleAlias = (field, value, id) => {
    let roleId = id;
    // let roleName = name
    let inputVal = value;
    let inputField = field;
    let roles = this.state.tagRole;
    if (inputField == "provider_alias_input") {
      for (let i = 0; i < roles.length; i++) {
        if (roleId === roles[i].role_id) {
          roles[i].provider_alias_input = inputVal;
          // roles[i].role_name = roleName
          //console.log("united", roles[i].provider_alias_input);
        }
      }
    }
    console.log(roles, "united roles");
    this.setState({ tagRole: roles });
    console.log(this.state.tagRole, "val");
    return;
  };

  handleHelp = (field, value, id, i) => {
    const { title, description, values, title1, help } = this.state;

    console.log(i, "uu");
    let roleId = id;
    console.log(roleId, "idfdf");

    // let inputVal = []
    // inputVal[i] = value
    let inputVal = value;
    let inputField = field;
    let roles = this.state.tagRole;

    if (inputField == "title") {
      // let title = [...this.state.help ]
      // title.splice(i, 1, {
      //     title: inputVal
      //    });
      // this.setState({
      //     help: title
      // })
      this.setState((prevState) => ({
        title: [...prevState.title, inputVal],
      }));
      //, ()=>{console.log(this.state.title,'tr')}
    } else {
      // let description = [...this.state.help ]
      // description.splice(i, 1, {
      //     description: inputVal
      // });
      // this.setState({
      //     help: description
      // })

      this.setState((prevState) => ({
        description: [...prevState.description, inputVal],
      }));
    }
    // this.state.title1.push({
    //     title: this.state.title,
    //     description: this.state.description
    // })

    console.log(this.state.help, "yttyrtrt");
    this.setState({ tagRole: roles });
    //this.getHelp()
    return;
  };

  handleconfig = (e, field) => {
    const viewData = this.state.viewData;

    if (field === "min_qstn") {
      if (Number(e.target.value) < Number(this.state.viewData.max_qstn)) {
        this.setState({
          viewData: { ...viewData, [field]: parseInt(e.target.value) || 0 },
        });
        console.log(this.state.viewData, "min");
      }

      return;
    } else if (field === "max_qstn") {
      this.setState({
        viewData: { ...viewData, [field]: parseInt(e.target.value) || 0 },
      });

      return;
    } else if (field === "unresponded_email") {
      this.setState({
        viewData: { ...viewData, [field]: String(e.target.value) },
      });

      return;
    } else if (field === "unresponded_sms") {
      this.setState({
        viewData: { ...viewData, [field]: Number(e.target.value) },
      });

      return;
    } else if (field === "trigger_email") {
      if (this.state.viewData.active_chat_closure >= Number(e.target.value)) {
        this.setState({
          viewData: { ...viewData, [field]: Number(e.target.value) || 0 },
        });
        return;
      }
      return message.error(
        "Trigger Email should be less than Active Session Closure"
      );
    } else if (field === "trigger_sms") {
      if (this.state.viewData.active_chat_closure >= Number(e.target.value)) {
        this.setState({
          viewData: { ...viewData, [field]: Number(e.target.value) || 0 },
        });
        return;
      }
      return message.error(
        "Trigger SMS should be less than Active Session Closure"
      );
    } else if (field === "session") {
      this.setState({
        viewData: { ...viewData, [field]: parseInt(e.target.value) || 0 },
      });
    } else if (field === "respond_overdue") {
      this.setState({
        viewData: { ...viewData, [field]: parseInt(e.target.value) || 0 },
      });
    } else if (field === "active_chat_closure") {
      this.setState({
        viewData: { ...viewData, [field]: parseInt(e.target.value) || 0 },
      });
    } else {
      this.setState({
        viewData: { ...viewData, [field]: parseInt(e.target.value) },
      });
    }
  };

  addClick() {
    this.setState((prevState) => ({ values: [...prevState.values, ""] }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }

  renderfields = () => {
    const { values, tagRole, id, title1 } = this.state;
    // alert(item)
    return (
      <div className="labelone">
        {
          // this.state.tagRole.map(item => {
          this.state.values.map((item, i) => {
            console.log(this.state.values.length, "valueeee");
            // if(item.isVissible=== true){
            return (
              <div key={i}>
                <Form>
                  <Form.Item
                    label={"Title"}
                    style={{ display: "contents", position: "static" }}
                    name={item.role_id + "title"}
                    required="true"
                    rules={[
                      {
                        required: true,
                        message: "Please enter title",
                      },
                      // {
                      //   // pattern: /^[a-zA-Z_@./#&+- ]*$/,
                      //   pattern: /^[a-zA-Z._^%$#!~@,-]*$/,
                      //   message: "Please enter a valid Title",
                      // },
                    ]}
                  >
                    <input
                      //key={item.role_id}
                      ref={this.formRef}
                      className="label-input"
                      placeholder=""
                      type="text"
                      onBlur={(e) =>
                        this.handleHelp(
                          "title",
                          e.target.value,
                          this.state.id,
                          i
                        )
                      }
                      //onChange={this.handleChange1.bind(this, item)}
                      value={item.title || ""}
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Description"}
                    style={{ display: "contents", position: "static" }}
                    name={item.role_id + "desc"}
                    required="true"
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                      // {
                      //   pattern: /^[ a-zA-Z0-9._^%$#!~@,-]*$/,
                      //   message: "Please enter a valid Description",
                      // },
                    ]}
                  >
                    <TextArea
                      ref={this.formRef}
                      //key={item.role_id}
                      className="label-input"
                      placeholder=""
                      type="text"
                      onBlur={(e) =>
                        this.handleHelp(
                          "description",
                          e.target.value,
                          this.state.id,
                          i
                        )
                      }
                      //onChange={this.handleChange1.bind(this, item)}
                      value={item.description || ""}
                    ></TextArea>
                  </Form.Item>
                  {/* <Form.Item>
                                <Button type="primary"
                                    shape="round"
                                    icon={<MinusOutlined />}
                                    onClick={this.removeClick.bind(this,i)}
                                    size="middle" />
                
                            </Form.Item> */}
                </Form>
              </div>
            );

            // }
          })

          // })
        }
      </div>
    );
  };

  getValues = (field, checkedValues) => {
    const { formData, id } = this.state;
    let close = [],
      specialityarr = [],
      permissionarr = [],
      timeframearr = [],
      orgarr = [];
    let viewData = this.state.viewData;
    console.log(field, "getvalFF");
    console.log(checkedValues, "getvalCC");

    if (field === "provider_alias") {
      let value = checkedValues;
      let roles = this.state.tagRole;

      if (Array.isArray(checkedValues) && checkedValues.length === 0) {
        for (let j = 0; j < roles.length; j++) {
          roles[j].isVissible = false;
        }
      } else {
        roles.forEach((element, index) => {
          if (element.isVissible) {
            if (checkedValues.findIndex((x) => x === element.role_id) === -1) {
              element.isVissible = false;
            }
          } else {
            if (checkedValues.findIndex((x) => x === element.role_id) !== -1) {
              element.isVissible = true;
            }
          }
        });
      }

      this.setState({ tagRole: roles });
      return;
    }

    if (field === "at_help") {
      let value = checkedValues;
      this.setState({ id: value });
      let roles = this.state.tagRole;

      if (Array.isArray(checkedValues) && checkedValues.length === 0) {
        for (let j = 0; j < roles.length; j++) {
          roles[j].isVissible = false;
        }
      } else {
        roles.forEach((element, index) => {
          if (element.isVissible) {
            if (checkedValues.findIndex((x) => x === element.role_id) === -1) {
              element.isVissible = false;
            }
          } else {
            if (checkedValues.findIndex((x) => x === element.role_id) !== -1) {
              element.isVissible = true;
            }
          }
        });
      }

      this.setState({ tagRole: roles });
      return;
    }

    //  if (field === 'at_help') {
    //     let value = checkedValues.target.value
    //     this.setState({id: value})
    //     let roles = this.state.tagRole

    //     if (checkedValues.target.value && checkedValues.target.value.length === 0) {
    //         for (let j = 0; j < roles.length; j++) {
    //             roles[j].isVissible = false
    //         }
    //     } else {
    //         roles.forEach((element, index) => {
    //             if (element.isVissible) {
    //                 if (checkedValues.target.value.indexOf(x => x === element.role_id) === -1) {
    //                     element.isVissible = false
    //                 }
    //             } else {
    //                 if (checkedValues.target.value.indexOf(x => x === element.role_id) !== -1) {
    //                     element.isVissible = true
    //                 }
    //             }
    //         });
    //     }

    //     this.setState({ tagRole: roles })
    //     return
    // }

    if (checkedValues.target) {
      if (checkedValues.target.type === "checkbox") {
        let viewData = this.state.viewData;
        this.setState({
          viewData: {
            ...viewData,
            [field]: checkedValues.target.checked ? 1 : 0,
          },
        });
        return;
      }
      if (checkedValues.target.type === "radio") {
        this.setState({
          viewData: {
            ...viewData,
            [field]: checkedValues.target.value,
          },
        });
        return;
      }
    } else if (Array.isArray(checkedValues)) {
      this.setState({
        viewData: {
          ...viewData,
          [field]: checkedValues,
        },
      });

      return;
    } else if (
      typeof checkedValues === "string" ||
      typeof checkedValues === "number"
    ) {
      this.setState({
        viewData: {
          ...viewData,
          [field]: checkedValues,
        },
      });

      return;
    }

    if (field === "close_codes_master") {
      // console.log(checkedValues[0],'line 222')

      let val = this.state.closecode;

      if (this.state.closecode.length > 0) {
        //   console.log(this.state.closecode,'1221')
        for (let i = 0; i < this.state.closecode.length; i++) {
          if (this.state.closecode[i].includes(checkedValues)) {
            return false;
          } else {
            close.push(this.state.closecode[i]);
          }
        }
      }

      close.push(checkedValues[0]);

      this.setState({
        closecode: close,
      });

      this.setState({
        formData: { ...formData, [field]: close },
      });
    } else if (field === "speciality_master") {
      this.setState({
        speciality1: checkedValues,
        formData: { ...formData, [field]: specialityarr },
      });
    } else if (field === "permission_master") {
      if (this.state.permission.length > 0) {
        //   console.log(this.state.closecode,'1221')
        for (let i = 0; i < this.state.permission.length; i++) {
          if (this.state.permission[i].includes(checkedValues)) {
            return false;
          } else {
            permissionarr.push(this.state.permission[i]);
          }
        }
      }
      permissionarr.push(checkedValues[0]);

      this.setState({
        permission: permissionarr,
      });
      this.setState({
        formData: { ...formData, [field]: permissionarr },
      });
    } else if (field === "timeframes") {
      if (this.state.timeframes.length > 0) {
        //   console.log(this.state.closecode,'1221')
        for (let i = 0; i < this.state.timeframes.length; i++) {
          if (this.state.timeframes[i].includes(checkedValues)) {
            return false;
          } else {
            timeframearr.push(this.state.timeframes[i]);
          }
        }
      }

      timeframearr.push(checkedValues[0]);

      this.setState({
        timeframes: timeframearr,
      });
      this.setState({
        formData: { ...formData, [field]: timeframearr },
      });
    }

    /*  if(this.state.video){
            // this.setState({video: 1});
             this.setState({
                 formData: {...formData, video:1}
             })
 
         }
        
       if(checkedValues == 1){
            this.setState({
                formData: {...formData, video: 1,AI: 0,doc_to_doc: 0,consumer_to_doc: 0}
            })
         }*/
  };

  updateChange = (e, field) => {
    if (field === "timeframe") {
      this.setState({ time_frame_name: e }, function () {});
    }
  };

  handleTimeframeChange = (e, field) => {
    if (field === "timeframe") {
      this.setState({ timeframeName: e }, function () {});
    }
  };

  handleSubGroupChange = (e, field) => {
    if (field === "sub_groups") {
      this.setState({ subgroupName: e }, function () {});
      // let group = this.state.viewData.sub_groups.concat(
      //   this.state.subgroupName
      // );
      // this.setState({
      //   viewData: {
      //     ...viewData,
      //     [field]: group,
      //   },
      // });
      // this.setState((prevState) => ({
      //   sub_groups: [...prevState.sub_groups, this.state.subgroupName],
      // }));
    }
  };

  doSorting(data) {
    let sortSpec = data;
    let orgTag = sortSpec.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    return orgTag;
  }
  specSort(data) {
    let sortSpec = data;
    let orgTag = sortSpec.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    return orgTag;
  }
  filteredData(searchQuery) {
    this.setState({
      filteredProvider:
        searchQuery === ""
          ? this.state.provider
          : this.state.provider.filter((item) => {
              const fullName =
                `${item.first_name} ${item.last_name}`.toLowerCase();
              return fullName.includes(searchQuery.toLowerCase());
            }),
    });
  }

  getProvaideralias() {
    let roles = this.state.tagRole;
    let count = 0;
    let provider_alias = this.state.viewData.provider_alias
      ? this.state.viewData.provider_alias
      : [];

    return new Promise((resolve, reject) => {
      roles.map((item) => {
        count++;
        if (item.isVissible) {
          //to match the role_id and if match overwrite the role_alias for the same
          for (let i = 0; i < this.state.viewData.provider_alias.length; i++) {
            if (
              provider_alias[i]["role_id"] === item.role_id &&
              item.provider_alias_input !== ""
            ) {
              provider_alias[i]["role_alias"] =
                item.provider_alias_input.charAt(0).toUpperCase() +
                item.provider_alias_input.slice(1);
              return;
            }
          }
          if (
            typeof item.provider_alias_input === "string" &&
            item.provider_alias_input.length > 0 &&
            item.provider_alias_input !== ""
          ) {
            provider_alias.push({
              role_id: item.role_id,
              role_alias:
                item.provider_alias_input.charAt(0).toUpperCase() +
                item.provider_alias_input.slice(1),
              // role_name: item.role_name
            });
          }
        }
        console.log("yenchaulla1", this.state.provider_alias_input);
        console.log("yenchaUlla2", item);
      });
      console.log(count, "cLen");
      console.log(roles.length, "cLenR");
      if (count === roles.length) {
        console.log(provider_alias, "alias");
        resolve(provider_alias);
      }
    });
  }

  getHelp() {
    const { role, messagess, id, title, description, values, title1, help } =
      this.state;

    if (this.state.title.length === 0 || this.state.description.length === 0) {
      message.error("Please enter the proper data");
      return;
    }

    console.log(this.state.help, "ttt");
    let roles = this.state.tagRole;
    let count = 0;
    let messages = [];

    return new Promise((resolve, reject) => {
      roles.map((item) => {
        count++;
        if (item.isVissible) {
          for (let i = 0; i < this.state.title.length; i++) {
            // if (typeof (this.state.title[i]) === "string" && typeof(this.state.description[i]) === "string" && this.state.title[i].length > 0 && this.state.title[i] !== '' &&
            //     this.state.description[i].length > 0 && this.state.description[i] !== '') {

            this.setState({ role: this.state.id });
            console.log(this.state.title, "dfdfdf");

            messages.push({
              title:
                this.state.title[i].charAt(0).toUpperCase() +
                this.state.title[i].slice(1),
              description:
                this.state.description[i].charAt(0).toUpperCase() +
                this.state.description[i].slice(1),
              // title: this.state.title,
              // description: this.state.description.charAt(0).toUpperCase() + this.state.description.slice(1),
              // role_name: item.role_name
            });
            if (
              messages[i]["title"] === "" ||
              messages[i]["description"] === ""
            ) {
              message.error("Please enter the proper data");
              return;
            }
            // }
          }
        }
      });
      this.setState({ messagess: messages });
      console.log(count, "cLen");
      console.log(roles.length, "cLenR");
      if (count === roles.length) {
        console.log(messagess, "alias");
        resolve(messagess);
      }
    });
  }

  async addHelp() {
    const provider_alias = await this.getHelp();
    console.log(this.formRef.current.value, "loggg");
    // const val = await this.formRef.current.validateFields()
    //    if(this.formRef.current.value === undefined) {
    //     message.error('please enter the valid values')
    //     return
    //     }
    let body = {
      role_id: this.state.id.toString(),
      org_id: this.state.formData.org_id,
      messages: this.state.messagess,
    };
    console.log(body, "hhgg");
    ApiService.adddetails
      .addHelp(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //console.log(data)
          message.success("Successfully updated the help");
          this.setState({ visiblehelp: false });

          // this.props.history.push("/organization-list");
          // {
          //   sessionStorage.getItem("rolename") === "Admin" &&
          //     this.props.history.push("/add-orgn");
          // }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        //  console.log(error)

        //  if (error.data.statusCode === 500) {
        //    message.error('Organization already exist');
        //   }
      });
  }

  async doConfigure() {
    const { viewData } = this.state;
    this.setState({ isLoading: true });

    // const email = String(this.state.viewData.unresponded_email)

    // this.setState({
    //     viewData: {...viewData, unresponded_email:email}
    // })
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    // Later in your code, use the emailRegex to validate the entered email
    if (
      this.state.viewData.is_sr_email &&
      !emailRegex.test(this.state.viewData.sr_email)
    ) {
      // Invalid email entered
      message.error("Enter valid SR email");
      this.setState({ isLoading: false });
      return;
      // ... handle the invalid email case
    }
    let address = {
      address_suite:
        this.state.viewData.address_suite ||
        this.state.viewData.address_obj.address_suite,
      city: this.state.viewData.city || this.state.viewData.address_obj.city,
      state: this.state.viewData.state || this.state.viewData.address_obj.state,
      zipcode:
        this.state.viewData.zipcode || this.state.viewData.address_obj.zipcode,
    };
    const body = Object.assign({}, this.state.viewData);
    delete body.close_codes_master;
    delete body.createdAt;
    delete body.speciality_master;
    delete body.updatedAt;
    delete body.status;
    delete body.permission_master;
    const provider_alias = await this.getProvaideralias();
    console.log("sopo1", body);
    console.log("sopo2", this.state.tagRole);
    //console.log("sopo3", this.getValues("provider_alias", true));

    body.clinical_qtn_info =
      this.state.viewData.clinical_qtn_info === null
        ? ""
        : this.state.viewData.clinical_qtn_info;
    body.provider_alias = provider_alias;
    body.AI_spec = [""];
    body.timeframes = [];
    body.sub_groups = [];
    body.address_obj = address;
    body.timeframes = [];
    body.image_url = sessionStorage.getItem("orgProfile")
      ? sessionStorage.getItem("orgProfile")
      : this.state.formData.image_url; //for updateing image
    Object.keys(body).map((keys) => {
      if (body[keys] === null) {
        body[keys] = 0;
      }
      if (keys === "app_url" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "app_name" && body[keys] === 0) {
        body[keys] = "";
      }
      if (keys === "sr_email" && body[keys] === 0) {
        body[keys] = "";
      }
    });
    //console.log('body', JSON.stringify(body));
    console.log(body, "api");
    console.log("danny", body.provider_alias);
    ApiService.adddetails
      .editOrg(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          //console.log(data)
          message.success("Successfully updated the Organization");
          this.setState({ visible: false });
          this.setState({ visiblestaff: false, visibletimeframe: false });

          //this.props.history.push("/organization-list");
          // {
          //   sessionStorage.getItem("rolename") === "Admin" &&
          //     this.props.history.push("/add-orgn");
          // }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        //  console.log(error)

        //  if (error.data.statusCode === 500) {
        //    message.error('Organization already exist');
        //   }
      });
  }

  getStaff = () => {
    var bodyFormData = new FormData();
    let body = {
      org_id: this.state.formData.org_id,
    };
    ApiService.adddetails.atStaff(body).then((data) => {
      if (data.status === 200) {
        // let resData = Array.from(data.data);
        // let newData = []
        // console.log(resData,'org')
        // for (let i = 0; i < resData.length; i++) {
        //     newData.push({danny
        //     userId: resData[i]['user_id'],
        //     first_name: resData[i]['first_name'],
        //     last_name: resData[i]['last_name'],
        //     })
        // }
        this.setState({
          staff: data.data,
        });
      }
      console.log(this.state.staff, "ss");
    });
  };

  getProvider = () => {
    var bodyFormData = new FormData();
    let body = {
      org_id: this.state.formData.org_id,
    };
    ApiService.adddetails.staffProvider(body).then((data) => {
      if (data.status === 200) {
        // let resData = Array.from(data.data);
        // let newData = []
        // console.log(resData,'org')
        // for (let i = 0; i < resData.length; i++) {
        //     newData.push({
        //     Id: resData[i]['user_id'],
        //     user_first_name: resData[i]['first_name'],
        //     user_last_name: resData[i]['last_name'],
        //     })
        // }
        this.setState({ filteredProvider: data.data, provider: data.data });
        console.log("hola1", data.data);
      }
      // console.log(this.state.staff,'ff')
    });
  };

  getVariables = () => {
    let bodys = {
      org_id: this.state.formData.org_id,
    };

    ApiService.adddetails.viewOrg(bodys).then((data) => {
      console.log(data.data, "close");
      if (data.status === 200) {
        let newData = data.data;
        Object.keys(newData).map((key) => {
          if (!newData[key]) {
            // newData[key] = ''
          }
          if (key === "close_codes" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "permission" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "speciality" && !newData[key]) {
            newData[key] = [];
          }
          if (key === "size" && !newData[key]) {
            newData[key] = 4;
          }
        });

        this.setState({
          SpecialityFullyLoaded: true,
          viewData1: newData["close_codes_master"],
          permData: newData["permission_master"],
          specData: newData["speciality_master"],
          timeframeData: newData["tf_orgwide"],
          subgroupData: newData["sub_groups"],
        });
        this.setState({ name: newData["name"] });
      }
      console.log(this.state.timeframeData, "originall");
    });
  };

  Tablecolumns = () => [
    {
      title: "Provider Name",
      dataIndex: [],
      render: (url, record) => {
        // console.log(record,'eee')
        return (
          <div value={record["user_id"]}>
            {record["first_name"]} {record["last_name"]}
          </div>
        );
      },
      width: "30%",
    },

    {
      title: "Staff",
      dataIndex: [],
      render: (url, record) => {
        const options = [];

        this.state.staff.map((item, index) => {
          options.push({
            label: item.first_name,
            value: item.user_id,
          });
        });
        return (
          <StaffProvider
            org_id={this.state.formData.org_id}
            user_id={record["user_id"]}
            getValues={(field, e) => this.getValues(field, e)}
            options={options}
            doSorting={this.doSorting}
          />
          // <Select
          //   style={{
          //     minWidth: "100%",
          //   }}
          //   mode="multiple"
          //   placeholder="Select Staff"
          //   options={this.doSorting(options)}
          //   onChange={(selectedValues) =>
          //     this.handleStaffChangeValues(selectedValues)
          //   }
          //   value={(field, e) => this.getValues(field, e)} // Make sure you have 'values' in your component state
          // />
        );
      },
      width: "100%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // console.log(text, record)

        return (
          <Button
            onClick={(e) => {
              const ele = document.querySelectorAll(
                `[checkboxid="${record["user_id"]}"]`
              );
              const tr = document.querySelectorAll(
                `[data-row-key="${record["user_id"]}"]`
              );
              const div = document.createElement("div");
              div.id = "wrapper-" + record["user_id"];

              const checkedElem = ele[0].querySelectorAll(
                ".ant-checkbox-checked>input[value]"
              );
              if (checkedElem.length < 1) {
                message.info("setting empty");
                // e.preventDefault()
                // return
              }

              div.style.position = "absolute";
              div.style.width = "100%";
              div.style.height = "100%";
              div.style.zIndex = "100";
              div.style.background = "transparent";
              div.style.left = 0;
              tr[0].style.filter = "blur(1px)";
              tr[0].appendChild(div);

              const staffList = [];
              checkedElem.forEach((e) => staffList.push(e["value"]));

              ApiService.general
                .provider_staff_mapping({
                  provider_id: record["user_id"],
                  org_id: this.state.formData.org_id,
                  staff: staffList,
                })
                .then((res) => {
                  tr[0].removeChild(div);
                  tr[0].style.filter = "unset";
                });
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  Tablecolumns1 = () => [
    {
      title: "Close code Variables",
      dataIndex: [],
      render: (url, record) => {
        // console.log(record,'eee')
        return <div value={record["id"]}>{record["close_code"]}</div>;
      },
      width: "30%",
    },

    {
      title: "Roles",
      dataIndex: [],
      render: (url, record) => {
        const options = [];

        this.state.tagRoles.map((item, index) => {
          options.push({
            label: item.role_name,
            value: item.role_id,
          });
        });
        return (
          <VariableConfig
            org_id={this.state.formData.org_id}
            id={record["id"]}
            name={record["close_code"]}
            getValues={(field, e) => this.getValues(field, e)}
            options={options}
            doSorting={this.doSorting}
          />
        );
      },
      width: "auto",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // console.log(text, record)

        return (
          <Button
            onClick={(e) => {
              const ele = document.querySelectorAll(
                `[checkboxid="${record["id"]}"]`
              );
              const tr = document.querySelectorAll(
                `[data-row-key="${record["id"]}"]`
              );
              const div = document.createElement("div");
              div.id = "wrapper-" + record["id"];

              const checkedElem = ele[0].querySelectorAll(
                ".ant-checkbox-checked>input[value]"
              );
              if (checkedElem.length < 1) {
                message.info("setting empty");
                // e.preventDefault()
                // return
              }

              div.style.position = "absolute";
              div.style.width = "100%";
              div.style.height = "100%";
              div.style.zIndex = "100";
              div.style.background = "transparent";
              div.style.left = 0;
              tr[0].style.filter = "blur(1px)";
              tr[0].appendChild(div);

              const roleList = [];
              checkedElem.forEach((e) => roleList.push(e["value"]));

              ApiService.general
                .add_org_activity_maps({
                  activity_id: record["id"],
                  activity_name: record["close_code"],
                  activity: "at_close_code",
                  org_id: this.state.formData.org_id,
                  roles: roleList,
                })
                .then((res) => {
                  tr[0].removeChild(div);
                  tr[0].style.filter = "unset";
                });
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  Tablecolumns2 = () => [
    {
      title: "Permission Variables",
      dataIndex: [],
      render: (url, record) => {
        // console.log(record,'eee')
        return <div value={record["id"]}>{record["name"]}</div>;
      },
      width: "30%",
    },

    {
      title: "Roles",
      dataIndex: [],
      render: (url, record) => {
        const options = [];

        this.state.tagRoles.map((item, index) => {
          options.push({
            label: item.role_name,
            value: item.role_id,
          });
        });
        return (
          <VariableConfig
            org_id={this.state.formData.org_id}
            id={record["id"]}
            name={record["name"]}
            getValues={(field, e) => this.getValues(field, e)}
            options={options}
            doSorting={this.doSorting}
          />
        );
      },
      width: "auto",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // console.log(text, record)

        return (
          <Button
            onClick={(e) => {
              const ele = document.querySelectorAll(
                `[checkboxid="${record["id"]}"]`
              );
              const tr = document.querySelectorAll(
                `[data-row-key="${record["id"]}"]`
              );
              const div = document.createElement("div");
              div.id = "wrapper-" + record["id"];

              const checkedElem = ele[0].querySelectorAll(
                ".ant-checkbox-checked>input[value]"
              );
              if (checkedElem.length < 1) {
                message.info("setting empty");
                // e.preventDefault()
                // return
              }

              div.style.position = "absolute";
              div.style.width = "100%";
              div.style.height = "100%";
              div.style.zIndex = "100";
              div.style.background = "transparent";
              div.style.left = 0;
              tr[0].style.filter = "blur(1px)";
              tr[0].appendChild(div);

              const roleList = [];
              checkedElem.forEach((e) => roleList.push(e["value"]));

              ApiService.general
                .add_org_activity_maps({
                  activity_id: record["id"],
                  activity_name: record["name"],
                  activity: "at_permission",
                  org_id: this.state.formData.org_id,
                  roles: roleList,
                })
                .then((res) => {
                  tr[0].removeChild(div);
                  tr[0].style.filter = "unset";
                });
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  Tablecolumns3 = () => [
    {
      title: "Speciality Variables",
      dataIndex: [],
      render: (url, record) => {
        // console.log(record,'eee')
        return <div value={record["spec_id"]}>{record["name"]}</div>;
      },
      width: "30%",
    },

    {
      title: "Roles",
      dataIndex: [],
      render: (url, record) => {
        const options = [];
        //prod revert
        //for new speciality add -> specialityTagRoles instead of tagRoles
        this.state.tagRoles.map((item, index) => {
          options.push({
            label: item.role_name,
            value: item.role_id,
          });
        });
        return (
          <VariableConfig
            org_id={this.state.formData.org_id}
            id={record["spec_id"]}
            name={record["name"]}
            getValues={(field, e) => this.getValues(field, e)}
            options={options}
            doSorting={this.doSorting}
          />
        );
      },
      width: "auto",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // console.log(text, record)

        return (
          <Button
            onClick={(e) => {
              const ele = document.querySelectorAll(
                `[checkboxid="${record["spec_id"]}"]`
              );
              const tr = document.querySelectorAll(
                `[data-row-key="${record["spec_id"]}"]`
              );
              const div = document.createElement("div");
              div.id = "wrapper-" + record["spec_id"];

              const checkedElem = ele[0].querySelectorAll(
                ".ant-checkbox-checked>input[value]"
              );
              if (checkedElem.length < 1) {
                message.info("setting empty");
                // e.preventDefault()
                // return
              }

              div.style.position = "absolute";
              div.style.width = "100%";
              div.style.height = "100%";
              div.style.zIndex = "100";
              div.style.background = "transparent";
              div.style.left = 0;
              tr[0].style.filter = "blur(1px)";
              tr[0].appendChild(div);

              const roleList = [];
              checkedElem.forEach((e) => roleList.push(e["value"]));

              ApiService.general
                .add_org_activity_maps({
                  activity_id: record["spec_id"],
                  activity_name: record["name"],
                  activity: "at_speciality",
                  org_id: this.state.formData.org_id,
                  roles: roleList,
                })
                .then((res) => {
                  tr[0].removeChild(div);
                  tr[0].style.filter = "unset";
                });
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  Tablecolumns4 = () => [
    {
      title: "Time Frame",
      dataIndex: "name",
      // render: (name) => <>{name}</>,
      render: (url, record) => {
        return (
          <span>
            {record.name !== null ? record["name"] : record["orgwide_name"]}
          </span>
        );
      },
      width: "75%",
    },
    {
      title: "Action",
      key: "action",
      width: "25%",
      render: (url, record) => {
        return (
          <span>
            <div id="table-action">
              {this.state.viewData.timeframe_orgwide === 1 ? (
                <Tooltip title="Edit">
                  <EditOutlined
                    id="table-edit"
                    style={{ margin: 14 }}
                    onClick={() =>
                      this.timeFrameEditModal(
                        record["id"],
                        record["tf_id"],
                        record["name"]
                      )
                    }
                  ></EditOutlined>
                </Tooltip>
              ) : (
                <EditOutlined style={{ margin: 14, opacity: 0.5 }} />
              )}
              <div
                onClick={() =>
                  this.showDeleteConfirm(record["id"], record["status"])
                }
              >
                <Link
                  to={{
                    pathname: "add-orgn",
                    state: { id: record["id"], type: "delete" },
                  }}
                >
                  <Switch
                    style={{ margin: 10 }}
                    loading={this.state.rowLoader}
                    checked={
                      record["status"] === 0 || record["status"] === null
                        ? false
                        : true
                    }
                  >
                    {}
                  </Switch>
                </Link>
              </div>
              <Tooltip title="Delete">
                <DeleteOutlined
                  id="table-edit"
                  style={{ margin: 14 }}
                  onClick={() => this.showDeleteModal(record["id"])}
                ></DeleteOutlined>
              </Tooltip>
              {/* <a className="drag-handle" href="#">
                <FullscreenExitOutlined />
              </a> */}
              <a className="drag-handle" href="#">
                <FullscreenExitOutlined />
              </a>
            </div>
          </span>
        );
      },
    },
  ];

  render() {
    const {
      tagOrg,
      speciality,
      viewData,
      viewData1,
      permData,
      subgroupData,
      specData,
      formData,
      SpecialityFullyLoaded,
      tagRole,
      tagRoles,
      rearranges,
      timeframeData,
    } = this.state;
    const {
      visible,
      visiblestaff,
      visiblehelp,
      visiblevariable,
      visibletimeframe,
      visibletimeframeedit,
      loading,
    } = this.state;
    const { type } = formData;
    // const [commands, setCommands] = useState([]);
    console.log(this.state.viewData, "view");

    const roles_options = [];
    tagRole.map((item, index) => {
      roles_options.push({
        label: item.role_name,
        value: item.role_id,
      });
    });
    const roles_default = [...tagRole.map((x) => x).flat()];

    const spec = [];
    let orgTag = tagOrg.sort(function (a, b) {
      return a.org_type.localeCompare(b.org_type);
    });

    let specTag = speciality.sort(function (a, b) {
      return a.spec_id.localeCompare(b.spec_id);
    });

    let listOrgTag =
      orgTag.length > 0 &&
      orgTag.map((items, i) => {
        return (
          <option key={items.id} value={items.id}>
            {items.org_type}
          </option>
        );
      }, this);

    let listspeciality =
      specTag.length > 0 &&
      specTag.map((items, i) => {
        return (
          <option key={items.spec_id} value={items.spec_id}>
            {items.name}
          </option>
        );
      }, this);

    let roleTag = tagRole.sort(function (a, b) {
      return a.role_name.localeCompare(b.role_name);
    });

    let listRole =
      roleTag.length > 0 &&
      roleTag.map((items, i) => {
        return (
          <option key={items.role_id} value={items.role_id}>
            {items.role_name}
          </option>
        );
      }, this);

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.timeframeData];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({
          data,
        });
        let rearrange = [];
        for (let i = 0; i < data.length; i++) {
          rearrange.push({
            tf_id: data[i]["id"],
            order: i,
          });
        }
        that.setState({
          rearranges: rearrange,
        });
        let body = {
          type: "orgwide_rearrange",
          rearrange: that.state.rearranges,
        };
        ApiService.adddetails
          .edittimeframe(body)
          .then((data) => {
            that.setState({ isLoading: false });
            if (data.status === 200) {
              message.success("Successfully rearranged the Time frame");
              that.getVariables();
            }
          })
          .catch((error) => {
            that.setState({ isLoading: false });

            // if (error.data.status === 702) {
            //   message.error("time frame already exist");
            // }
          });
        console.log(rearrange, "dddddd");
      },
      handleSelector: "a",
    };

    // const onClickHandler = (event) => {
    //   setCommands((prevCommands) => {
    //     const newCommand = {
    //       id: new Date().getTime().toString(),
    //       title: "",
    //       description: ""
    //     };
    //     return [...prevCommands, newCommand];
    //   });
    // };

    // const titleChangeHandler = (event, id) => {
    //   const title = event.target.value;
    //   setCommands((prevCommands) => {
    //     return prevCommands.map((command) => {
    //       if (command.id === id) {
    //         return {
    //           ...command,
    //           title
    //         };
    //       } else {
    //         return command;
    //       }
    //     });
    //   });
    // };
    // const descriptionChangeHandler = (event, id) => {
    //   const description = event.target.value;
    //   setCommands((prevCommands) => {
    //     return prevCommands.map((command) => {
    //       if (command.id === id) {
    //         return {
    //           ...command,
    //           description
    //         };
    //       } else {
    //         return command;
    //       }
    //     });
    //   });
    // };

    // const handleSubmit = (event) => {
    //   event.preventDefault();
    //   console.log(commands);
    // };

    return type !== "add" && !SpecialityFullyLoaded ? (
      <> loading..</>
    ) : (
      <div className="body-color">
        <Container>
          <Form
            onFinish={() => this.validateForm()}
            initialValues={{
              //org_npi: this.state.viewData.org_npi,
              name: this.state.viewData.name
                ? this.state.viewData.name
                : this.state.formData.name,
              Organization: this.state.viewData.org_type
                ? this.state.viewData.org_type
                : this.state.formData.org_type,
              Primary_Contact: this.state.viewData.primary_contact
                ? this.state.viewData.primary_contact
                : this.state.formData.primary_contact,
              Secondary_Contact: this.state.viewData.secondary_contact
                ? this.state.viewData.secondary_contact
                : this.state.formData.secondary_contact,
              Speciality: this.state.viewData.speciality,
              Address: this.state.viewData.address
                ? this.state.viewData.address
                : this.state.formData.address,
              Address_Suite: this.state.formData.address_obj
                ? this.state.formData.address_obj.address_suite
                : "",
              City: this.state.formData.address_obj
                ? this.state.formData.address_obj.city
                : "",
              State: this.state.formData.address_obj
                ? this.state.formData.address_obj.state
                : "",
              Zipcode: this.state.formData.address_obj
                ? this.state.formData.address_obj.zipcode
                : "",
              clinical_qtn_info: this.state.viewData.clinical_qtn_info,
            }}
          >
            <div className="row-1">
              <h3 className="heading-font">Add Organization</h3>
              <ProfileUpload
                image_url={this.state.formData.image_url}
                orgId={this.state.formData.org_id}
              />
            </div>

            {/* <div className='row-1'>

                            <div className='col-fields' > */}

            {/* <FormItem
                            name="org_npi"
                            label="Org NPI"
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a valid NPI'
                                },
                                {
                                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                                    message: 'Please enter a valid NPI'
                                }
                            ]}

                        >

                            <Input className='text-input'
                                id=""
                                placeholder="NPI"
                                value={this.state.viewData.org_npi || ''}
                                onChange={(e) => this.getValues('org_npi', e.target.value)}
                            />

                        </FormItem> */}

            <FormItem
              label="Name"
              name="name"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter a Organization Name",
                },
                {
                  pattern: /^(?!\s)[A-Za-z\s]*(?<!\s)$/,
                  message:
                    "Please enter a valid Organization Name without leading or trailing spaces and only containing letters",
                },
              ]}
            >
              <Input
                className="text-input"
                id=""
                placeholder="Organization Name"
                value={this.state.viewData.name || ""}
                onChange={(e) => this.getValues("name", e.target.value)}
                //onChange={() => this.onFinish()}
              />
              {/* <div className='error-msg-notification'>{this.state.errors.name}</div>     */}
            </FormItem>
            {/* </div>

                            <div className='col-fields' > */}

            <Form.Item
              label="Organization Type"
              name="Organization"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please select Organization",
                },
              ]}
            >
              <Select
                showSearch
                className="text-select"
                // mode="tags"
                placeholder="Please select Organization Type"
                onChange={(e) => this.getValues("org_type", e)}
                value={this.state.viewData.org_type || ""}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {listOrgTag}
              </Select>
              {/* <div className='error-msg-notification'>{this.state.errors.name}</div>     */}
            </Form.Item>
            {/* 
                            </div>
                        </div>
                        <div className='row-1'>
                            <div className='col-fields' > */}
            <Form.Item
              label="Primary Contact"
              name="Primary_Contact"
              className="display"
              rules={[
                // {
                //   required: true,
                //   message: "Please choose Primary contact",
                // },
                {
                  pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                  message: "Please enter a valid Phone number",
                },
              ]}
            >
              <Input
                className="text-input"
                id=""
                placeholder="Primary Contact"
                // pattern="\d" //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                value={this.state.viewData.primary_contact || ""}
                onChange={(e) =>
                  this.getValues("primary_contact", e.target.value)
                }
              />
              {/* <div className='error-msg-notification'>{this.state.errors.name}</div>     */}
            </Form.Item>
            {/* </div>
                            <div className='col-fields' > */}
            <Form.Item
              label="Secondary Contact"
              name="Secondary_Contact"
              className="display"
              rules={[
                // {
                //   required: true,
                //   message: "Please choose Seconary Contact",
                // },
                {
                  pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                  message: "Please enter a valid Phone number",
                },
              ]}
            >
              <Input
                className="text-input"
                id=""
                placeholder="Secondary Contact"
                value={this.state.viewData.secondary_contact || ""}
                onChange={(e) =>
                  this.getValues("secondary_contact", e.target.value)
                }
              />
            </Form.Item>
            {/* <div className='error-msg-notification'>{this.state.errors.name}</div>     */}
            {/* </div> */}
            {/* <div className='col-fields' >
                                <Form.Item
                                    name="Speciality"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Speciality'
                                        },

                                    ]}
                                >
                                    <Select showSearch
                                        className='text-select'
                                        // mode="tags"
                                        mode="multiple"

                                        placeholder="Please select speciality"
                                        onChange={(e) => this.getValues('speciality', e)}
                                        value={this.state.viewData.speciality}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {listspeciality}
                                    </Select>
                                 </Form.Item>
                            </div> */}

            {/* </div>*/}
            <Form.Item
              label="Address Line 1"
              name="Address"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter Address",
                },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    "Please enter a valid Address without leading or trailing spaces",
                },
              ]}
              style={{ width: "45%" }}
            >
              <TextArea
                rows={4}
                maxLength=""
                placeholder="Address"
                autoComplete="nope"
                value={this.state.viewData.address}
                onChange={(e) => this.getValues("address", e.target.value)}
              ></TextArea>
            </Form.Item>
            <Form.Item
              label="Address Line 2"
              name="Address_Suite"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter Address Suite",
                },
              ]}
              style={{ width: "45%", float: "right", marginTop: "-155px" }}
            >
              <TextArea
                rows={4}
                maxLength=""
                placeholder="Address-Suite"
                autoComplete="nope"
                value={this.state.formData.address_suite || ""}
                onChange={(e) =>
                  this.getValues("address_suite", e.target.value)
                }
              ></TextArea>
            </Form.Item>

            <Form.Item
              label="City"
              name="City"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter City",
                },
                {
                  pattern: /^(?!\s)[A-Za-z\s]*(?<!\s)$/,
                  message:
                    "Please enter a valid City Name without leading or trailing spaces and only containing letters.",
                },
              ]}
              style={{ width: "45%" }}
            >
              <Input
                className="text-input"
                id=""
                placeholder="City"
                value={this.state.formData.city || ""}
                onChange={(e) => this.getValues("city", e.target.value)}
              />
            </Form.Item>
            {/* 
                        <Form.Item
                            label='State'
                            name="State"
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter State'
                                },
                                {
                                    pattern: /^[a-zA-Z ]*$/,
                                    message: 'Please enter a valid state Name'

                                },
                            ]}
                            style={{ width: '45%', float: 'right', marginTop: '-123px' }}
                        >
                            <Input className='text-input'
                                id=""
                                placeholder="State"
                                value={this.state.viewData.state || ''}
                                onChange={(e) => this.getValues('state', e.target.value)} />
                        </Form.Item> */}

            <FormItem
              name="State"
              label="State"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter State",
                },
              ]}
              style={{ width: "45%", float: "right", marginTop: "-123px" }}
            >
              <Select
                showSearch
                placeholder="State"
                optionFilterProp="children"
                onChange={(e) => this.getValues("state", e)}
              >
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="DC">District of Columbia</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </FormItem>

            <Form.Item
              label="Zip Code"
              name="Zipcode"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter Zip Code",
                },
                {
                  pattern: /^(?!\s)\d*(?<!\s)$/,
                  message:
                    "Please enter a valid ZIP code without leading or trailing spaces and only containing numbers.",
                },
              ]}
              style={{ width: "45%" }}
            >
              <Input
                className="text-input"
                maxLength="5"
                id=""
                placeholder="Zip Code"
                value={this.state.formData.zipcode || ""}
                onChange={(e) => this.getValues("zipcode", e.target.value)}
              />
            </Form.Item>

            {/* <div className='error-msg-notification'>{this.state.errors.name}</div>     */}
            <Row className="button-padding">
              {this.state.buttonName !== "" && (
                <Button
                  className="btn-type"
                  id=""
                  loading={this.state.isLoading}
                  htmlType="submit"
                  type="primary"
                >
                  {this.state.buttonName}
                </Button>
              )}

              {/* {<Button type="primary" onClick={this.showModal}>Configuaration</Button>} */}
              <Button
                id=""
                type="primary"
                className="btn-type"
                onClick={this.showModal}
              >
                {this.state.button}
              </Button>
              <Button
                id=""
                type="primary"
                className="btn-type"
                onClick={this.variableModal}
              >
                {this.state.button3}
              </Button>
              {this.state.viewData.timeframe_orgwide === 1 && (
                <Button
                  id=""
                  type="primary"
                  className="btn-type"
                  onClick={this.timeFrameModal}
                >
                  {this.state.button4}
                </Button>
              )}
              <Button
                id=""
                type="primary"
                className="btn-type"
                onClick={this.showStaffModal}
              >
                {this.state.button1}
              </Button>
              <Button
                id=""
                type="primary"
                className="btn-type"
                onClick={this.helpModal}
              >
                {this.state.button2}
              </Button>
              <Modal
                className="staff_modal"
                // wrapClassName="staff_modal_wrap"
                centered
                visible={visiblevariable}
                title={<div>Variable Configuration</div>}
                onOk={this.handleOk1}
                onCancel={this.handleCancel1}
                footer={[
                  <div>
                    <Button key="back" onClick={this.handleCancel1}>
                      Return
                    </Button>
                    ,
                  </div>,
                ]}
              >
                <div>
                  <Table
                    columns={this.Tablecolumns1()}
                    rowKey={(record) => record.id}
                    dataSource={this.state.viewData1}
                    pagination={this.state.pagination}
                    //onChange={this.handleTableChange()}
                    // onChange={record =>  this.handleTableChange(record.user_id)}
                  />
                </div>
                <div>
                  <Table
                    columns={this.Tablecolumns2()}
                    rowKey={(record) => record.id}
                    dataSource={this.state.permData}
                    pagination={this.state.pagination}
                  />
                </div>
                <div>
                  <Table
                    columns={this.Tablecolumns3()}
                    rowKey={(record) => record.spec_id}
                    dataSource={this.specSort(this.state.specData)}
                    pagination={this.state.pagination}
                  />
                </div>
              </Modal>

              <Modal
                className="staff_modal"
                // wrapClassName="staff_modal_wrap"
                centered
                visible={visiblestaff}
                title={<div>@STAFF</div>}
                onOk={this.handleOk1}
                onCancel={this.handleCancel1}
                footer={[
                  <div>
                    <Button key="back" onClick={this.handleCancel1}>
                      Return
                    </Button>
                    ,
                  </div>,
                ]}
              >
                <div>
                  {/* work in progress */}
                  <div className="sub-bar">
                    <Search
                      placeholder="Search by Provider Name"
                      //value={searchQuery}
                      onChange={(e) => {
                        this.filteredData(e.target.value);
                      }}
                    />
                  </div>
                  <Table
                    columns={this.Tablecolumns()}
                    rowKey={(record) => record.user_id}
                    dataSource={this.state.filteredProvider}
                    pagination={this.state.pagination}
                    //onChange={this.handleTableChange()}
                    // onChange={record =>  this.handleTableChange(record.user_id)}
                  />
                </div>
              </Modal>

              <Modal
                className="staff_modal"
                // wrapClassName="staff_modal_wrap"
                centered
                visible={visibletimeframe}
                title={<div>TIME FRAMES</div>}
                onOk={this.handleOk4}
                onCancel={this.handleCancel4}
                footer={[
                  <div>
                    <Button key="back" onClick={this.handleCancel4}>
                      Return
                    </Button>
                    <Button
                      htmlType="submit"
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={() => this.doConfigure()}
                    >
                      Submit
                    </Button>
                  </div>,
                ]}
              >
                <Row style={{ marginBottom: "-30px" }}>
                  <div>
                    <Link to={{ pathname: "" }}>
                      <Button
                        className="add-btn"
                        id=""
                        onClick={() => this.setModalVisible(true)}
                        style={{ marginLeft: "40rem" }}
                      >
                        Add Time Frame
                      </Button>
                    </Link>
                  </div>
                </Row>
                <Row>
                  <Col span={24}>
                    <div>
                      <ReactDragListView {...dragProps}>
                        <Table
                          columns={this.Tablecolumns4()}
                          rowKey={(record) => record.id}
                          dataSource={this.state.timeframeData}
                          pagination={this.state.pagination}
                          //onChange={this.handleTableChange()}
                          // onChange={record =>  this.handleTableChange(record.user_id)}
                        />
                      </ReactDragListView>
                    </div>
                  </Col>
                </Row>
                {/* <p style={{ paddingTop: 21 }}>Custom</p>
                <div className="code">
                  {viewData &&
                    Object.keys(viewData).map((keys, index) => {
                      if (keys === "tf_orgwide") {
                        const options = [];
                        viewData.tf_orgwide.map((item, index) => {
                          if (item.name === "Custom") {
                            options.push({
                              label: item.name,
                              value: item.id,
                            });
                          }
                        });
                        const defvals = [
                          ...viewData.tf_orgwide.map((x) => x).flat(),
                        ];
                        return (
                          <div>
                            <Checkbox.Group
                              style={{ width: "100%" }}
                              defaultValue={defvals}
                              // name={`close_code${index}`}
                              //options={options}
                              options={this.doSorting(options)}
                              onChange={(ev) =>
                                this.getValues("timeframes", ev)
                              }
                            ></Checkbox.Group>
                          </div>
                        );
                      }
                    })}
                </div> */}
              </Modal>

              <Modal
                className="staff_modal"
                centered
                visible={visiblehelp}
                title={<div>@HELP</div>}
                onOk={this.handleOk2}
                onCancel={this.handleCancel2}
                footer={[
                  <div>
                    <Button key="back" onClick={this.handleCancel2}>
                      Return
                    </Button>
                    <Button
                      htmlType="submit"
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={() => this.addHelp()}
                    >
                      Submit
                    </Button>
                  </div>,
                ]}
              >
                <div className="code">
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    defaultValue={roles_default}
                    options={this.doSorting(roles_options)}
                    onChange={(e) => this.getValues("at_help", e)}
                    //onClick={(e)=>this.onClick2(e)}
                  ></Checkbox.Group>
                </div>
                <div className="labelone">
                  {this.state.tagRole.map((item) => {
                    if (item.isVissible === true) {
                      return (
                        <Form>
                          {this.renderfields()}
                          <Form.Item>
                            <Button
                              type="primary"
                              shape="round"
                              icon={<PlusOutlined />}
                              onClick={this.addClick.bind(this)}
                              size="middle"
                            />
                          </Form.Item>
                        </Form>
                      );
                    }
                  })}
                </div>
              </Modal>

              <Modal
                //id="ant-modal-wrap"
                className="config_modal"
                visible={visible}
                title={<div>CONFIGURATION</div>}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                  <div>
                    <Button key="back" onClick={this.handleCancel}>
                      Return
                    </Button>
                    <Button
                      htmlType="submit"
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={() => this.doConfigure()}
                    >
                      Submit
                    </Button>
                  </div>,
                ]}
              >
                {/* <p>close code</p>
                          {
                          
                              console.log(this.state, 'line no.530')
                              
                          }
                          <List
                            size="default"
                            dataSource={viewData || []}
                            renderItem={item =>{
                                console.log(item,'hello')
                                return (
                                    <Checkbox checked="true"><List.Item.Meta
                                         title={<a className="chatitem1_name" href="#">{item['close_code']}</a>}
                                     /></Checkbox> 
 
                             )
                                (
                                    <Checkbox checked="true"><List.Item.Meta
                                         title={<a className="chatitem1_name" href="#">{item['close_code']}</a>}
                                     /></Checkbox> 
 
                             )

                            }}

                        />*/}

                <p className="config-title">Custom Label</p>
                <div className="code">
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    defaultValue={roles_default}
                    options={this.doSorting(roles_options)}
                    onChange={(e) => this.getValues("provider_alias", e)}
                    //onClick={(e)=>this.onClick2(e)}
                  ></Checkbox.Group>
                </div>
                <div className="label-class">
                  {this.state.tagRole.map((item) => {
                    console.log("bull", item);
                    if (item.isVissible === true) {
                      return (
                        <Form.Item
                          label={item.role_name + " " + "Alias"}
                          style={{
                            display: "flex",
                            width: "61vw !important",
                            position: "static",
                          }}
                          name={item.role_id}
                          required="true"
                          rules={[
                            {
                              required: true,
                              message: "Please enter name",
                            },
                            {
                              pattern: /^[a-zA-Z ]*$/,
                              message: "Please enter a valid Name",
                            },
                          ]}
                        >
                          <input
                            //key={item.role_id}
                            value={item.provider_alias_input}
                            className="label-input"
                            placeholder=""
                            type="text"
                            onChange={(e) =>
                              this.handleAlias(
                                "provider_alias_input",
                                e.target.value,
                                item.role_id
                              )
                            }
                          />
                          <label></label>
                        </Form.Item>
                      );
                    }
                  })}
                </div>
                {/* <p className='config-title'>Close Codes</p>
                                <div className="code">
                                    {

                                        viewData && Object.keys(viewData).map((keys, index) => {
                                            if (keys === "close_codes_master") {
                                                const options = []
                                                viewData.close_codes_master.map((item, index) => {
                                                    options.push({
                                                        label: item.close_code,
                                                        value: item.id
                                                    })

                                                })
                                                const defvals = [...viewData.close_codes.map(x => x).flat()];
                                                return (
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        defaultValue={defvals}
                                                        // name={`close_code${index}`}
                                                        //options={options}
                                                        options={this.doSorting(options)}
                                                        onChange={(ev) => this.getValues('close_codes', ev)}
                                                    >
                                                    </Checkbox.Group>
                                                )

                                            }
                                        })
                                    }</div> */}
                {/* <p className='config-title'>Specialty</p>
                                <div className="spec">

                                    {

                                        viewData && Object.keys(viewData).map((keys, index) => {
                                            if (keys === "speciality_master") {

                                                const options = []
                                                viewData.speciality_master.map((item, index) => {

                                                    options.push({
                                                        label: item.name,
                                                        value: item.spec_id
                                                    })
                                                })

                                                const selectedspec = viewData.speciality || []
                                                const defvals = [...selectedspec.map(x => x).flat()];

                                                return (
                                                    <Checkbox.Group
                                                        style={{ width: '100%', display: 'grid' }}
                                                        onChange={(ev) => this.getValues('speciality', ev)}
                                                        defaultValue={defvals}
                                                        //options={options}
                                                        options={this.doSorting(options)}

                                                    >
                                                        {/* <Row>
                                                        <Col span={24}>
                                                            <div className=""> <Checkbox value={item.spec_id}><div className="chatitem1_name" key={index}>{item.name}</div></Checkbox></div>
                                                        </Col>
                                                    </Row> */}
                {/* </Checkbox.Group>)
                                            }

                                        })
                                    }</div>  */}
                {/* 
                                <p className='config-title'>Participant Codes</p>
                                <div className="partcode">
                                    {
                                        viewData && Object.keys(viewData).map((keys, index) => {
                                            const options = []

                                            if (keys === "permission_master") {

                                                viewData.permission_master.map((item, index) => {

                                                    options.push({
                                                        label: item.name,
                                                        value: item.id
                                                    })


                                                })
                                                const defvals = [...viewData.permission.map(x => x).flat()];

                                                return <Checkbox.Group
                                                    style={{ width: '100%' }}
                                                    defaultValue={defvals}
                                                    // options={options}
                                                    options={this.doSorting(options)}
                                                    onChange={(ev) => this.getValues('permission', ev)}>
                                                    {/* <Row>
                                                        <Col span={12}>
                                                            <Checkbox value={item.id}><div className="chatitem1_name" key={index}>{item.name}</div></Checkbox>
                                                        </Col>
                                                    </Row> */}
                {/* </Checkbox.Group>
                                            }
                                        })
                                    }</div> */}

                <p className="config-title">Features</p>

                <div className="feature">
                  <Checkbox
                    value={this.state.viewData.video_call}
                    checked={this.state.viewData.video_call}
                    onChange={(ev) => this.getValues("video_call", ev)}
                  >
                    Video Call
                  </Checkbox>
                  <Checkbox
                    value={this.state.viewData.AI}
                    checked={this.state.viewData.AI}
                    onChange={(ev) => this.getValues("AI", ev)}
                  >
                    Afya AI
                  </Checkbox>
                  <Checkbox
                    value={this.state.viewData.doc_to_doc}
                    checked={this.state.viewData.doc_to_doc}
                    onChange={(ev) => this.getValues("doc_to_doc", ev)}
                  >
                    Provider to Provider
                  </Checkbox>
                  <Checkbox
                    value={this.state.viewData.consumer_to_doc}
                    checked={this.state.viewData.consumer_to_doc}
                    onChange={(ev) => this.getValues("consumer_to_doc", ev)}
                  >
                    Consumer to Provider
                  </Checkbox>
                </div>

                <p className="config-title">White Label?</p>
                <Radio.Group
                  className="whitelabel-part1"
                  onChange={(e) => this.getValues("white_label", e)}
                  value={this.state.viewData.white_label}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>

                {this.state.viewData.white_label === 1 && (
                  <div className="whitelabel-part2">
                    <Input.Group>
                      Name Of App:{" "}
                      <input
                        type="text"
                        onChange={(e) => this.handleconfig(e, "app_name")}
                        value={this.state.viewData.app_name || ""}
                        className="whitelbl"
                        placeholder=""
                      />
                      Url Of App:{" "}
                      <input
                        type="text"
                        onChange={(e) => this.handleconfig(e, "app_url")}
                        value={this.state.viewData.app_url || ""}
                        className="whitelbl"
                        placeholder=""
                      />
                      {/*Org Logo: <input type="file" className="file"/>*/}
                    </Input.Group>
                  </div>
                )}

                <p className="config-title">AI Configuaration</p>
                <div className="conf-class">
                  <Input.Group>
                    Max No.of Questions:{" "}
                    <input
                      type="number"
                      onChange={(e) => this.handleconfig(e, "max_qstn")}
                      value={this.state.viewData.max_qstn || 0}
                      className="lbl"
                      placeholder=""
                    />
                    Min No.of Questions:{" "}
                    <input
                      type="number"
                      onChange={(e) => this.handleconfig(e, "min_qstn")}
                      value={this.state.viewData.min_qstn || 0}
                      className="lbl"
                      placeholder=""
                    />
                  </Input.Group>
                </div>

                <p className="config-title">Modifier Value</p>
                <div>
                  <Input.Group className="modifier-class">
                    <Checkbox
                      onChange={(ev) => this.getValues("mod_yes", ev)}
                      checked={this.state.viewData.mod_yes}
                      value={this.state.viewData.photo || 0}
                    >
                      Yes
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("mod_no", ev)}
                      checked={this.state.viewData.mod_no}
                      value={this.state.viewData.mod_no || 0}
                    >
                      No
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("mod_maybe", ev)}
                      checked={this.state.viewData.mod_maybe}
                      value={this.state.viewData.mod_maybe || 0}
                    >
                      Maybe
                    </Checkbox>
                  </Input.Group>
                </div>

                <p className="config-title">Media allowed in Chat</p>
                <div className="media-class">
                  <Checkbox
                    onChange={(ev) => this.getValues("photo", ev)}
                    checked={this.state.viewData.photo}
                    value={this.state.viewData.photo || 0}
                  >
                    Photo
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("camera", ev)}
                    checked={this.state.viewData.camera}
                    value={this.state.viewData.camera || 0}
                  >
                    Camera
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("attachment", ev)}
                    checked={this.state.viewData.attachment}
                    value={this.state.attachment || 0}
                  >
                    Attachment
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("video", ev)}
                    checked={this.state.viewData.video}
                    value={this.state.viewData.video || 0}
                  >
                    Video
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("audio", ev)}
                    checked={this.state.viewData.audio}
                    value={this.state.viewData.audio || 0}
                  >
                    Audio
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("GCP_record_video", ev)}
                    checked={this.state.viewData.GCP_record_video}
                    value={this.state.viewData.GCP_record_video || 0}
                  >
                    GCP Record
                  </Checkbox>
                </div>

                <p style={{ marginLeft: 56, paddingTop: 21 }}>
                  Fields allowed in View Patient
                </p>
                <div className="field-allowed">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_first_name", ev)}
                      checked={this.state.viewData.is_first_name}
                      value={this.state.viewData.is_first_name || 0}
                      style={{ marginLeft: 8 }}
                    >
                      First Name
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_middle_name", ev)}
                      checked={this.state.viewData.is_middle_name}
                      value={this.state.viewData.is_middle_name || 0}
                    >
                      Middle Name
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_last_name", ev)}
                      checked={this.state.viewData.is_last_name}
                      value={this.state.is_last_name || 0}
                    >
                      Last Name
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_dob", ev)}
                      checked={this.state.viewData.is_dob}
                      value={this.state.viewData.is_dob || 0}
                    >
                      DOB
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_address", ev)}
                      checked={this.state.viewData.is_address}
                      value={this.state.viewData.is_address || 0}
                    >
                      Address
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_city", ev)}
                      checked={this.state.viewData.is_city}
                      value={this.state.viewData.is_city || 0}
                    >
                      City
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_state", ev)}
                      checked={this.state.viewData.is_state}
                      value={this.state.viewData.is_state || 0}
                    >
                      State
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_zip_code", ev)}
                      checked={this.state.viewData.is_zip_code}
                      value={this.state.viewData.is_zip_code || 0}
                    >
                      Zipcode
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_country", ev)}
                      style={{ marginLeft: 8 }}
                      checked={this.state.viewData.is_country}
                      value={this.state.is_country || 0}
                    >
                      Country
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_mobile", ev)}
                      style={{ marginLeft: 8 }}
                      checked={this.state.viewData.is_mobile}
                      value={this.state.is_mobile || 0}
                    >
                      Mobile
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_sex", ev)}
                      checked={this.state.viewData.is_sex}
                      value={this.state.viewData.is_sex || 0}
                    >
                      Sex
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_email", ev)}
                      checked={this.state.viewData.is_email}
                      value={this.state.viewData.is_email || 0}
                    >
                      Email
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_mrn", ev)}
                      checked={this.state.viewData.is_mrn}
                      value={this.state.viewData.is_mrn || 0}
                    >
                      MRN
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_home_phone", ev)}
                      checked={this.state.viewData.is_home_phone}
                      value={this.state.viewData.is_home_phone || 0}
                    >
                      Home Phone
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_employer", ev)}
                      checked={this.state.viewData.is_employer}
                      value={this.state.viewData.is_employer || 0}
                    >
                      Employer
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_insurence", ev)}
                      checked={this.state.viewData.is_insurence}
                      value={this.state.viewData.is_insurence || 0}
                    >
                      Insurance
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_member_id", ev)}
                      style={{ marginLeft: 8 }}
                      checked={this.state.viewData.is_member_id}
                      value={this.state.is_member_id || 0}
                    >
                      Member ID
                    </Checkbox>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "15px",
                    marginLeft: "66px",
                  }}
                >
                  Do you need an auto generated Email:
                  {/* <Checkbox style={{paddingLeft:'15px'}} onChange={(ev) => this.getValues('is_email_generated', ev)} checked={this.state.viewData.is_email_generated} value={this.state.viewData.is_email_generated || 0} ></Checkbox> */}
                  <Radio.Group
                    style={{ paddingLeft: "15px" }}
                    onChange={(ev) => this.getValues("is_email_generated", ev)}
                    checked={this.state.viewData.is_email_generated}
                    value={this.state.viewData.is_email_generated}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </div>

                <div style={{ paddingTop: "15px", marginLeft: "66px" }}>
                  <Checkbox
                    onChange={(ev) => this.getValues("is_icd_present", ev)}
                    checked={this.state.viewData.is_icd_present}
                    value={this.state.viewData.is_icd_present || 0}
                  >
                    ICD
                  </Checkbox>
                  <Checkbox
                    onChange={(ev) => this.getValues("is_cpt_present", ev)}
                    checked={this.state.viewData.is_cpt_present}
                    value={this.state.viewData.is_cpt_present || 0}
                  >
                    CPT
                  </Checkbox>
                </div>

                <div
                  className="conf-class"
                  style={{ fontWeight: "bold", paddingTop: "15px" }}
                >
                  <Input.Group>
                    <span style={{ fontSize: "14px" }}>
                      Unresponded Chat Overdue Limit(days):
                    </span>{" "}
                    <input
                      style={{ margin: 10 }}
                      onChange={(e) => this.handleconfig(e, "respond_overdue")}
                      value={this.state.viewData.respond_overdue || 0}
                      className="lbl"
                      placeholder="no.of days"
                    />
                    <span style={{ fontSize: "14px" }}>
                      Active Session Closure(days):
                    </span>{" "}
                    <input
                      style={{ margin: 10 }}
                      onChange={(e) =>
                        this.handleconfig(e, "active_chat_closure")
                      }
                      value={this.state.viewData.active_chat_closure || 0}
                      className="lbl"
                      placeholder="no.of days"
                    />
                    <div style={{ paddingTop: 20 }}>
                      <span style={{ fontSize: "14px" }}>
                        Trigger Email(days):
                      </span>{" "}
                      <input
                        style={{ margin: 10 }}
                        onChange={(e) => this.handleconfig(e, "trigger_email")}
                        value={this.state.viewData.trigger_email || 0}
                        className="lbl"
                        placeholder="no.of days"
                      />
                      <span style={{ fontSize: "14px" }}>
                        Trigger SMS(days):
                      </span>{" "}
                      <input
                        style={{ margin: 10 }}
                        onChange={(e) => this.handleconfig(e, "trigger_sms")}
                        value={this.state.viewData.trigger_sms || 0}
                        className="lbl"
                        placeholder="no.of days"
                      />
                    </div>
                  </Input.Group>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Make Credit Card Required:
                  <Radio.Group
                    style={{ paddingLeft: "15px" }}
                    onChange={(ev) => this.getValues("credit_card", ev)}
                    checked={this.state.viewData.credit_card}
                    value={this.state.viewData.credit_card}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </div>
                <div
                  className="conf-class"
                  style={{ fontWeight: "bold", paddingTop: "15px" }}
                >
                  <Input.Group>
                    <span style={{ fontSize: "14px" }}>
                      # of sessions for refund:{" "}
                    </span>
                    <input
                      style={{ margin: 10 }}
                      min="1"
                      max="99"
                      onChange={(e) => this.handleconfig(e, "session")}
                      value={this.state.viewData.session || 0}
                      className="lbl"
                      placeholder="no.of session"
                    />
                    <br />
                    <span style={{ fontSize: "14px" }}>
                      Unresponded Email Notification:{" "}
                    </span>
                    <input
                      style={{ margin: 10 }}
                      min="1"
                      onChange={(e) =>
                        this.handleconfig(e, "unresponded_email")
                      }
                      value={this.state.viewData.unresponded_email || 0}
                      className="lbl"
                      placeholder="no.of session"
                    />
                    <span style={{ fontSize: "14px" }}>
                      Unresponded SMS Notification:{" "}
                    </span>
                    <input
                      style={{ margin: 10 }}
                      min="1"
                      onChange={(e) => this.handleconfig(e, "unresponded_sms")}
                      value={this.state.viewData.unresponded_sms || 0}
                      className="lbl"
                      placeholder="no.of session"
                    />
                  </Input.Group>
                </div>

                <div style={{ marginLeft: 62 }}>
                  <span style={{ fontSize: 14, fontWeight: 700 }}>
                    Clinical Question/Chief Complaint Information
                  </span>
                  <Form.Item
                    //label='Clinal Question Information'
                    name="clinical_qtn_info"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Clinical Question/Chief Complaint"
                      value={this.state.viewData.clinical_qtn_info}
                      onChange={(e) =>
                        this.getValues("clinical_qtn_info", e.target.value)
                      }
                    ></TextArea>
                  </Form.Item>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Provider Responder Auto Assign:
                  <Radio.Group
                    style={{ paddingLeft: "15px" }}
                    onChange={(ev) => this.getValues("is_auto_assign", ev)}
                    checked={this.state.viewData.is_auto_assign}
                    value={this.state.viewData.is_auto_assign}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Organization wide time frames:
                  <div
                    onClick={() =>
                      this.showTimeframe(this.state.viewData.timeframe_orgwide)
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.timeframe_orgwide === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Consumer Notification(SMS):
                  <div
                    onClick={() =>
                      this.showSmsNotification(
                        this.state.viewData.consumer_sms_notification
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.consumer_sms_notification === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                  Consumer Notification(Email):
                  <div
                    onClick={() =>
                      this.showEmailNotification(
                        this.state.viewData.consumer_email_notification
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.consumer_email_notification === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>

                {/* below code */}
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Consumer Notification Unresponded(SMS):
                  <div
                    onClick={() =>
                      this.showConsumerSubmitterUnrespondedSms(
                        this.state.viewData
                          .consumer_sms_notification_unresponded
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData
                          .consumer_sms_notification_unresponded === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Consumer Notification Unresponded(Email):
                  <div
                    onClick={() =>
                      this.showConsumerSubmitterUnrespondedEmail(
                        this.state.viewData
                          .consumer_email_notification_unresponded
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData
                          .consumer_email_notification_unresponded === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>
                {/* above code */}

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  VRP to Afya chat modularity:
                  <div
                    onClick={() =>
                      this.showVrpchat(
                        this.state.viewData.vrp_afya_chat_modularity
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.vrp_afya_chat_modularity === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  SMS Bidirectional:
                  <div
                    onClick={() =>
                      this.showSmsBidirectionalConfirmation(
                        this.state.viewData.is_bidirectional
                      )
                    }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.is_bidirectional === 0
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Email alert of Provider Responder (Specialist) Response:
                  <div>
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.is_sr_email === 0 ? false : true
                      }
                      onClick={() =>
                        // this.showEmailAlertProviderResponderConfirmation(
                        //   this.state.viewData.is_sr_email
                        // )
                        this.updateSaveEmailAlertOfProviderResponder(
                          this.state.viewData.is_sr_email
                        )
                      }
                    ></Switch>
                  </div>
                  {this.state.viewData.is_sr_email === 1 ? (
                    <div>
                      <input
                        style={{ margin: 10 }}
                        //onChange={(e) => this.handleconfig(e, "unresponded_sms")}
                        //value={this.state.viewData.unresponded_sms || 0}
                        className="lblEmail"
                        value={this.state.viewData.sr_email}
                        placeholder="Enter email address"
                        onChange={(e) => {
                          //this.setState({ this: e.target.value });
                          //console.log("hello1", this.state.offline_sr_email);
                          const viewData = this.state.viewData;
                          this.setState({
                            viewData: {
                              ...viewData,
                              sr_email: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Pathway for Generate temporary password and send to newly
                  created users:
                  <div>
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData?.temp_pswd_config
                          ?.is_temp_pswd_config === 0
                          ? false
                          : true
                      }
                      onClick={() =>
                        // this.showEmailAlertProviderResponderConfirmation(
                        //   this.state.viewData.is_sr_email
                        // )
                        this.updateSaveGenerateTemporaryPasswordConfig(
                          this.state.viewData.temp_pswd_config
                            .is_temp_pswd_config
                        )
                      }
                    ></Switch>
                  </div>
                  {this.state.viewData?.temp_pswd_config
                    ?.is_temp_pswd_config === 1 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: 10,
                        }}
                      >
                        <Checkbox
                          onChange={(ev) =>
                            this.handleTemporaryPasswordRolesCheckbox(
                              this.state.viewData?.temp_pswd_config
                                ?.is_responder_provider,
                              "is_responder_provider"
                            )
                          }
                          checked={
                            this.state.viewData?.temp_pswd_config
                              ?.is_responder_provider
                          }
                        >
                          Responder Provider
                        </Checkbox>
                        <Checkbox
                          onChange={(ev) =>
                            this.handleTemporaryPasswordRolesCheckbox(
                              this.state.viewData?.temp_pswd_config
                                ?.is_submitter_provider,
                              "is_submitter_provider"
                            )
                          }
                          checked={
                            this.state.viewData?.temp_pswd_config
                              ?.is_submitter_provider
                          }
                        >
                          Submitter Provider
                        </Checkbox>
                        <Checkbox
                          onChange={(ev) =>
                            this.handleTemporaryPasswordRolesCheckbox(
                              this.state.viewData?.temp_pswd_config
                                ?.is_submitter_staff,
                              "is_submitter_staff"
                            )
                          }
                          checked={
                            this.state.viewData?.temp_pswd_config
                              ?.is_submitter_staff
                          }
                        >
                          Submitter Staff
                        </Checkbox>
                        <Checkbox
                          onChange={(ev) =>
                            this.handleTemporaryPasswordRolesCheckbox(
                              this.state.viewData?.temp_pswd_config
                                ?.is_responder_staff,
                              "is_responder_staff"
                            )
                          }
                          checked={
                            this.state.viewData?.temp_pswd_config
                              ?.is_responder_staff
                          }
                        >
                          Responder Staff
                        </Checkbox>
                        <Checkbox
                          disabled
                          onChange={(ev) =>
                            this.handleTemporaryPasswordRolesCheckbox(
                              this.state.viewData?.temp_pswd_config
                                ?.is_submitter_consumer,
                              "is_submitter_consumer"
                            )
                          }
                          checked={
                            this.state.viewData?.temp_pswd_config
                              ?.is_submitter_consumer
                          }
                        >
                          Submitter Consumer
                        </Checkbox>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Private conversation / Sub conversation:
                  <div
                  // onClick={() =>
                  //   this.showPrivateChatSubChatConfirmation(
                  //     this.state.viewData.is_private_chat
                  //   )
                  // }
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.is_private_chat === 0 ? false : true
                      }
                      disabled
                      //when removing disabeld from here , also remove the comment from above div on click
                    >
                      {}
                    </Switch>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "3px",
                    marginLeft: "66px",
                  }}
                >
                  Same Specialty
                  <div
                    onClick={() =>
                      this.showSameSpecialityConfirmation(
                        this.state.viewData.at_same_spec
                      )
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      style={{ margin: 10 }}
                      checked={
                        this.state.viewData.at_same_spec === 0 ? false : true
                      }
                    ></Switch>
                    <Tooltip title="Enable this setting to allow Submitter Providers to bring in Provider Responders within the same specialty using @specialty in chat sessions, facilitating efficient eConsult communications.">
                      <InfoCircleOutlined style={{ fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                </div>

                <p style={{ marginLeft: 56, paddingTop: 21 }}>Email:</p>
                <div className="field-allowed">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues("is_email_hubchat_summary", ev)
                      }
                      checked={this.state.viewData.is_email_hubchat_summary}
                      value={this.state.viewData.is_email_hubchat_summary || 0}
                    >
                      Hubchat Summary
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues("is_email_close_code", ev)
                      }
                      checked={this.state.viewData.is_email_close_code}
                      value={this.state.viewData.is_email_close_code || 0}
                    >
                      Close codes
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues("is_email_participant_access", ev)
                      }
                      checked={this.state.viewData.is_email_participant_access}
                      value={
                        this.state.viewData.is_email_participant_access || 0
                      }
                    >
                      Participant access
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues(
                          "is_email_initial_welcome_notification",
                          ev
                        )
                      }
                      checked={
                        this.state.viewData
                          .is_email_initial_welcome_notification
                      }
                      value={
                        this.state.viewData
                          .is_email_initial_welcome_notification || 0
                      }
                    >
                      Initial welcome notification
                    </Checkbox>
                  </div>
                </div>
                <p style={{ marginLeft: 56, paddingTop: 21 }}>SMS:</p>
                <div className="field-allowed">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues("is_sms_hubchat_summary", ev)
                      }
                      checked={this.state.viewData.is_sms_hubchat_summary}
                      value={this.state.viewData.is_sms_hubchat_summary || 0}
                    >
                      Hubchat Summary
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) => this.getValues("is_sms_close_code", ev)}
                      checked={this.state.viewData.is_sms_close_code}
                      value={this.state.viewData.is_sms_close_code || 0}
                    >
                      Close codes
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues("is_sms_participant_access", ev)
                      }
                      checked={this.state.viewData.is_sms_participant_access}
                      value={this.state.viewData.is_sms_participant_access || 0}
                    >
                      Participant access
                    </Checkbox>
                    <Checkbox
                      onChange={(ev) =>
                        this.getValues(
                          "is_sms_initial_welcome_notification",
                          ev
                        )
                      }
                      checked={
                        this.state.viewData.is_sms_initial_welcome_notification
                      }
                      value={
                        this.state.viewData
                          .is_sms_initial_welcome_notification || 0
                      }
                    >
                      Initial welcome notification
                    </Checkbox>
                  </div>
                </div>

                {/* commenting below code and keeping it as backup, just in case
                it was told to be removed from the organization config */}
                {/* <p style={{ paddingTop: 21 }}>Specialty Config</p>
                <div className="code">
                  {viewData &&
                    Object.keys(viewData).map((keys, index) => {
                      if (keys === "speciality_master") {
                        const options = [];
                        viewData.speciality_master.map((item, index) => {
                          options.push({
                            label: item.name,
                            value: item.spec_id,
                          });
                        });
                        const defvals = [
                          ...viewData.qna_specs.map((x) => x).flat(),
                        ];
                        return (
                          <div>
                            <Checkbox.Group
                              style={{ width: "100%" }}
                              defaultValue={defvals}
                              options={this.doSorting(options)}
                              onChange={(ev) => this.getValues("qna_specs", ev)}
                            ></Checkbox.Group>
                          </div>
                        );
                      }
                    })}
                </div> */}

                <p style={{ paddingTop: 21 }}>Search Org Names</p>
                <div className="code">
                  {viewData &&
                    Object.keys(viewData).map((keys, index) => {
                      if (keys === "all_org") {
                        const options = [];
                        viewData.all_org.map((item, index) => {
                          options.push({
                            label: item.name,
                            value: item.org_id,
                          });
                        });

                        const defvals = [
                          ...viewData.search_org.map((x) => x).flat(),
                        ];
                        return (
                          <div>
                            {/* <Checkbox.Group
                              style={{ width: "100%" }}
                              defaultValue={defvals}
                              options={this.doSorting(options)}
                              onChange={(ev) =>
                                this.getValues("search_org", ev)
                              }
                            ></Checkbox.Group> */}

                            <Checkbox.Group
                              style={{ width: "100%" }}
                              defaultValue={defvals}
                              onChange={(ev) =>
                                this.getValues("search_org", ev)
                              }
                            >
                              <Row gutter={[16, 16]}>
                                {this.doSorting(options).map(
                                  (option, index) => (
                                    <Col span={8} key={"org_names" + index}>
                                      <Checkbox value={option.value}>
                                        {option.label}
                                      </Checkbox>
                                    </Col>
                                  )
                                )}
                              </Row>
                            </Checkbox.Group>
                          </div>
                        );
                      }
                    })}
                </div>
              </Modal>

              <Modal
                title="Add Time Frame"
                centered
                visible={this.state.modalVisible}
                okButtonProps={{ style: { display: "none" } }}
                onCancel={() =>
                  this.setState({
                    visible: !this.state.modalVisible,
                  })
                }
                footer={[
                  <div className="ant-modal-body-footer">
                    <Button
                      key="back"
                      onClick={() =>
                        this.setState({
                          modalVisible: !this.state.modalVisible,
                          timeframeName: "",
                        })
                      }
                    >
                      Return
                    </Button>
                    ,
                    <Button
                      htmlType="submit"
                      key="submit"
                      type="primary"
                      onClick={() => this.addTimeFrame()}
                    >
                      Submit
                    </Button>
                    ,
                  </div>,
                ]}
              >
                <div className="row-4">
                  <div className="modal_speciality">
                    <Input
                      className={
                        "text-input" + " " + this.state.ErrorInputClass
                      }
                      id=""
                      placeholder="Time Frame"
                      required
                      type="text"
                      value={this.state.timeframeName}
                      onChange={(e) =>
                        this.handleTimeframeChange(e.target.value, "timeframe")
                      }
                    />
                  </div>
                </div>
              </Modal>

              <Modal
                title="Edit Time Frame"
                centered
                visible={this.state.visibletimeframeedit}
                okButtonProps={{ style: { display: "none" } }}
                onCancel={() =>
                  this.setState({
                    visible: !this.state.visibletimeframeedit,
                  })
                }
                footer={[
                  <div className="ant-modal-body-footer">
                    <Button
                      key="back"
                      onClick={() =>
                        this.setState({
                          visibletimeframeedit: false,
                          timeframeName: "",
                        })
                      }
                    >
                      Return
                    </Button>
                    ,
                    <Button
                      htmlType="submit"
                      key="submit"
                      type="primary"
                      onClick={() => this.updateTimeFrameSave()}
                    >
                      Submit
                    </Button>
                    ,
                  </div>,
                ]}
              >
                <div className="row-4">
                  <div className="modal_speciality">
                    <Input
                      className={
                        "text-input" + " " + this.state.ErrorInputClass
                      }
                      id=""
                      placeholder="Time Frame"
                      required
                      type="text"
                      value={this.state.time_frame_name}
                      onChange={(e) =>
                        this.updateChange(e.target.value, "timeframe")
                      }
                    />
                  </div>
                </div>
              </Modal>
              {sessionStorage.getItem("rolename") !== "Admin" && (
                <Button
                  className={
                    this.state.formData.type === "add"
                      ? "btn-type12"
                      : "btn-type"
                  }
                  type="primary"
                  id=""
                  onClick={() => this.clearData()}
                >
                  Cancel
                </Button>
              )}
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}
