import React, { useState, useEffect } from "react";

import { Row, Button, Input, Form, message, Avatar, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Names from "./Prasantation/Name";
import Email from "./Prasantation/Email";
import Dob from "./Prasantation/Dob";
import Bio from "./Prasantation/Bio";
import { DOMAIN_NAME } from "../../../services/config";
import Password from "./Prasantation/Password";
import Org from "./Prasantation/Org";
import SubGroup from "./Prasantation/SubGroup";
import "./index.css";

import ApiService from "../../../services/api-services";
import firebase from "../../../services/firebase";

/*Profile Upload comp-----Startss */

export default (props) => {
  const { type = "add" } = props;
  const [initialFormLoad, setinitialFormLoad] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [initialValues, setinitialValues] = useState({});
  const [buttonName, setbuttonName] = useState("Submit");
  const [orgData, setOrgdata] = useState([]);
  const [groupData, setGroupdata] = useState([]);
  const [formRef] = Form.useForm();

  /*Profile Upload comp-----Startss */
  const ProfileUpload = ({ image_url, userId }) => {
    const filetypes = ["image/jpeg", "image/png", "image/gif"];
    const [load, setload] = useState(null);
    const [imagePreview, setimagePreview] = useState(image_url);
    const [active, setactive] = useState("edit");

    /* Editing Profile pictuew--Starts */
    const ImgUpload = ({ onChange, src }) => {
      console.log(image_url, "url");
      return (
        <label htmlFor="photo-upload" className="custom-file-upload fas">
          {src ? (
            <Spin spinning={load}>
              <img className="image-style" src={src} />
            </Spin>
          ) : (
            <Avatar
              shape="square"
              size="large"
              icon={<UserOutlined />}
              style={{
                borderRadius: "10%",
                position: "relative",
                height: 100,
                width: 100,
                padding: 23,
              }}
            />
          )}
          <input id="photo-upload" type="file" onChange={onChange} />
        </label>
      );
    };
    function SetUserprofileLocally(url) {
      sessionStorage.setItem("staffSubmitterProfile", url);
      message.success("Image uploaded Successfully");
    }
    function uploadImage(url, userId, file) {
      const imgUrl = URL.createObjectURL(file);
      fetch(imgUrl)
        .then((r) => r.blob())
        .then((res) => {
          var metadata = {
            contentType: "image/jpeg",
            cacheControl: "public,max-age=300",
          };

          const ref = firebase.storage().ref("/profile_url/" + userId);
          console.log(userId, "ref");
          const uploadTask = ref.put(res, metadata);

          const handleState = (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                setload(true);
                console.log("Upload is running");
                break;
            }
          };
          const handleError = (error) => {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
              // User canceled the upload
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          };
          const handleSuccess = () => {
            setload(false);
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log(downloadURL, "downloadURL");
                SetUserprofileLocally(downloadURL);
              });
          };
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            handleState,
            handleError,
            handleSuccess
          );
        });
    }
    const photoUpload = (e) => {
      console.log(e, "upload");
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!filetypes.includes(file.type)) {
        message.error("Upload valid image only png and jpeg are allowed");
        return;
      }

      if (file.size >= 1000000) {
        alert("file size is too large");
        return;
      }

      console.log(file, "src");
      reader.onloadend = () => {
        uploadImage(reader.result, userId, file);
        setimagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    };

    const Edit = ({ onSubmit, children }) => {
      return (
        <div className="card">
          <form onSubmit={onSubmit}>
            <div className="profile-card">
              <h1>User Profile</h1>
              {children}
            </div>
          </form>
        </div>
      );
    };
    /* Editing Profile pictuew--Endss */

    const handleSubmit = (e) => {
      e.preventDefault();
      let activeP = active === "edit" ? "profile" : "edit";
      setactive(activeP);
    };

    return (
      <div>
        <Edit onSubmit={handleSubmit}>
          <ImgUpload onChange={photoUpload} src={imagePreview} />
        </Edit>
      </div>
    );
  };
  /*Profile Upload comp-----Endss */
  // sprint35
  const RequestComponentGroupData = () => {
    let body = {
      type: "list_user",
    };
    ApiService.adddetails.subGroups(body).then((data) => {
      if (data.status === 200) {
        console.log(data, "dadra");
        const newData = data.data.filter((item) => {
          if (item.name && item.id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        setGroupdata(newData);
        // RequestComponentData();
        // setinitialFormLoad((d) => !d);
        //loading();
      }
    });
  };
  const loading = () => {
    setinitialFormLoad((d) => !d);
  };

  const RequestComponentData = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        setOrgdata(newData);
        RequestComponentGroupData();
        //loading();
        setinitialFormLoad((d) => !d);
      }
    });
  };

  useEffect(() => {
    RequestComponentData();
    RequestComponentGroupData();
    if (props.data) {
      console.log(props.data, "edidt staff");
      if (Object.keys(props.data).length > 0) {
        Object.keys(props.data).map((key) => {
          if (key === "experience") {
            formRef.setFieldsValue({
              experience: props.data[key] === 0 ? "" : props.data[key],
            });
          }
          //   if (key === "mobile") {
          //     formRef.setFieldsValue({
          //       mobile: props.data[key]
          //         ? props.data[key].substr(props.data[key] - 10)
          //         : "",
          //     });
          //   }
          if (
            key === "mobile" &&
            DOMAIN_NAME === "https://staging-test.afya.chat"
          ) {
            formRef.setFieldsValue({
              mobile: props.data[key] ? props.data[key].substr(3) : "",
            });
          }
          if (
            key === "mobile" &&
            (DOMAIN_NAME === "https://uat-test.afya.chat" ||
              DOMAIN_NAME === "https://backend.test-afya.afya.chat")
          ) {
            formRef.setFieldsValue({
              mobile: props.data[key] ? props.data[key].substr(2) : "",
            });
          }
        });
      }
      setinitialValues(props.data);
    }
    if (type === "edit") {
      return setbuttonName("Update");
    }
  }, [props.data]);

  const handleChange = (event, action) => {
    switch (action) {
      case "validateForm":
        console.log(event, "validateForm");
        break;
      case "clearData":
        props.cancel();
        break;
      default:
        break;
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    if (type === "edit") {
      const dobPattern = /^\d{2}-\d{2}-\d{4}$/;

      ApiService.adddetails
        .editSubmitterResponder({
          first_name: values.first_name,
          last_name: values.last_name,
          dob: dobPattern.test(values.dob) ? values.dob : "",
          hospital_name: values.hospital_name || "",
          email: values.email,
          //"password": values.password,
          medical_group: values.medical_group,

          mobile:
            DOMAIN_NAME === "https://staging-test.afya.chat"
              ? "+91" + values.mobile
              : "+1" + values.mobile,
          experience: parseInt(values.experience) || 0,
          image_url: sessionStorage.getItem("staffSubmitterProfile")
            ? sessionStorage.getItem("staffSubmitterProfile")
            : initialValues.image_url,
          about: values.about || "",
          org_details: initialValues.org_details,
          user_id: initialValues.user_id,
        })
        .then((response) => {
          if (response.status === 200) {
            //  alert('hii')
            //     this.props.history.push('/add-staff')
            sessionStorage.removeItem("staffSubmitterProfile");
            message.success("Successfully updated the Staff");
            props.cancel();
          }
          console.log(response);
        })
        .catch((error) => {
          if (error.data.status === 702) {
            message.error("Email ID already exist");
          }
        });
    } else {
      // console.log("hello1", values.dob);

      try {
        const dobPattern = /^\d{2}-\d{2}-\d{4}$/;
        // console.log("hello2", dobPattern.test(values.dob) ? values.dob : "");
        // const mobile = values.mobile;
        // console.log(mobile, "mbl");
        if (values.mobile) {
          values.mobile =
            DOMAIN_NAME === "https://staging-test.afya.chat"
              ? "+91" + values.mobile
              : "+1" + values.mobile;
        }
        sessionStorage.getItem("rolename") === "Super Admin" &&
          ApiService.adddetails
            .add_staff({
              first_name: values.first_name,
              last_name: values.last_name,
              dob: dobPattern.test(values.dob) ? values.dob : "",
              hospital_name: values.hospital_name || "",
              email: values.email,
              password: values.password,
              medical_group: values.medical_group || "",
              experience: parseInt(values.experience) || 0,
              mobile: values.mobile,
              // values.mobile !== undefined
              //   ? DOMAIN_NAME === "https://staging.afya.chat"
              //     ? "+91" + values.mobile
              //     : "+1" + values.mobile
              //   : "",
              image_url: values.image_url || "",
              about: values.about || "",
              org_details: [
                {
                  org_id: values.organization.value,
                  roles: [""],
                },
              ],
              image_url: sessionStorage.getItem("staffSubmitterProfile")
                ? sessionStorage.getItem("staffSubmitterProfile")
                : "",
            })
            .then((response) => {
              if (response.status === 200) {
                //  alert('hii')
                //     this.props.history.push('/add-staff')
                sessionStorage.removeItem("staffSubmitterProfile");
                message.success("Successfully added the Staff");
                props.cancel();
              }
              console.log(response);
            })
            .catch((error) => {
              if (error.data.status === 702) {
                message.error("Email ID already exist");
              }
            });

        sessionStorage.getItem("rolename") === "Admin" &&
          ApiService.adddetails
            .add_staff({
              first_name: values.first_name,
              last_name: values.last_name,
              dob: dobPattern.test(values.dob) ? values.dob : "",
              hospital_name: values.hospital_name || "",
              email: values.email,
              password: values.password,
              mobile:
                values.mobile !== undefined
                  ? DOMAIN_NAME === "https://staging-test.afya.chat"
                    ? "+91" + values.mobile
                    : "+1" + values.mobile
                  : "",
              experience: parseInt(values.experience) || 0,
              image_url: values.image_url || "",
              about: values.about || "",
              org_details: [
                {
                  org_id: sessionStorage.getItem("org_id"),
                  roles: [""],
                },
              ],
            })
            .then((response) => {
              if (response.status === 200) {
                //  alert('hii')
                // this.props.history.push('/add-staff')
                message.success("Successfully added the Staff");
                props.cancel();
              }
              console.log(response);
            })

            .catch((error) => {
              console.log(error);
            });
      } catch (error) {}
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return initialFormLoad ? (
    <>loading </>
  ) : (
    <div className="body-color">
      <div className="row-1">
        <h3 className="heading-font">
          {type === "edit" ? "Edit Submitter Staff" : "Add Submitter Staff"}
        </h3>
        <ProfileUpload
          image_url={initialValues.image_url}
          userId={initialValues.user_id}
        />
      </div>

      <Form
        form={formRef}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Names handleChange={handleChange} />
        <Dob
          handleChange={handleChange}
          form={formRef}
          type={type}
          initialValues={initialValues}
        />
        {/* {type==='add'? <Email handleChange={handleChange} />:null} */}
        <Email handleChange={handleChange} />
        {type === "add" ? (
          <Password handleChange={handleChange} type={type} />
        ) : null}
        {type === "add" ? (
          <Org handleChange={handleChange} data={orgData} />
        ) : null}

        <SubGroup handleChange={handleChange} data={groupData} />

        <Bio handleChange={handleChange} />

        {(type === "edit" || type === "add") && (
          <Row className="">
            <Button
              className="btn-type"
              id=""
              loading={isLoading}
              type="primary"
              onClick={(e) => handleChange(e, "validateForm")}
              htmlType="submit"
            >
              {buttonName}
            </Button>
            <Button
              className="btn-type"
              id=""
              type="primary"
              onClick={(e) => handleChange(e, "clearData")}
            >
              Cancel
            </Button>
          </Row>
        )}
      </Form>
    </div>
  );
};
