import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Layout,
  message,
  Form,
  Upload,
  Select,
  DatePicker,
  Radio,
} from "antd";
import { Container } from "reactstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import "../Admin/admin.css";
import ApiService from "../../services/api-services";
import { CONFIG, DOMAIN_NAME } from "../../services/config";
import UserContext from "../../UserContext";
import TextArea from "antd/lib/input/TextArea";
const FormItem = Form.Item;
const { Option } = Select;
const items = [];
const dateFormat = ["MM-DD-YYYY", "MM/DD/YYYY", "MMDDYYYY"];

export default class AddAdmin extends Component {
  static contextType = UserContext;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  constructor(props) {
    super(props);
    var org_id,
      type,
      formData = {};
    if (this.props.history.location.state !== undefined) {
      formData = {
        user_id: this.props.history.location.state.user_id,
        first_name: this.props.history.location.state.first_name,
        middle_name: this.props.history.location.state.middle_name,
        last_name: this.props.history.location.state.last_name,
        dob: this.props.history.location.state.dob,
        address: this.props.history.location.state.address,
        zipcode: this.props.history.location.state.zipcode,
        email: this.props.history.location.state.email,
        mobile: this.props.history.location.state.mobile,
        gender: this.props.history.location.state.gender,
        MRN: this.props.history.location.state.MRN,
        CIN: this.props.history.location.state.CIN,
        city: this.props.history.location.state.city,
        state: this.props.history.location.state.state,
        country: this.props.history.location.state.country,
        insurance: this.props.history.location.state.insurance,
        org_id: this.props.history.location.state.org_id,
        type: this.props.location.state.type,
        initialEmail: this.props.history.location.state.email,
      };

      this.formType = this.props.history.location.state.type;
    } else {
      formData = {
        type: "add",
      };
    }
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: formData,
      Orglist: [],
      rolelist: [],
      insurancelist: [],
      buttonName: "",
      formType: type,
    };
  }

  componentDidMount() {
    const { formData } = this.state;
    // console.log(this.props.history.location.state.type,'11')
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }

    // this.loadInitalFiles()

    // console.log("abc",this.state.formData.type)

    this.getOrganization();
    this.getRoles();
    this.getInsurance();
    console.log();
    if (this.state.formData.type === "edit") {
      this.setState({ buttonName: "Update" });
    }
    // else if (this.state.formData.type === 'delete') {
    //     this.setState({ buttonName: 'Delete', isDisabled: true });
    // }
    else {
      this.setState({
        formData: { ...formData, dob: "" },
      });
      this.setState({ buttonName: "Save" });
    }

    message.config({ top: 150, duration: 2 });
  }

  getInsurance = () => {
    ApiService.adddetails.masterInsurance().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ insurancelist: data.data });
      }
    });
  };

  validateForm() {
    // alert('asda')
    const data = this.state.formData;
    console.log("f", data);
    var regexp = new RegExp(/^[a-zA-Z0-9 ]+$/);

    if (data === "") {
      // message.warn("Please fill the details");
    } else {
      if (data.name === "") {
        message.warn("Please fill the details");
      } else {
        if (this.state.buttonName === "Update") {
          this.updateSave();
        } else if (this.state.buttonName === "Save") {
          this.doSave(); //change this
        } else {
          // this.deleteCompany();
        }
      }
    }
  }

  getOrganization() {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        this.setState({ Orglist: newData });
      }
    });
  }

  getRoles() {
    ApiService.adddetails.getRoleType().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ rolelist: data.data.response });
      }
    });
  }

  handleChange = (e, field) => {
    console.log(e, "rrre");
    const { formData } = this.state;

    if (field === "first_name") {
      console.log(e);
      this.setState({
        formData: { ...formData, [field]: e },
      });
      return;
    } else if (field === "middle_name") {
      console.log(e);
      this.setState({
        formData: { ...formData, [field]: e },
      });
      return;
    } else if (field === "last_name") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "email") {
      console.log(e);
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "password") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "consumer_orgs") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "dob") {
      e = moment(e).format("MM-DD-YYYY");
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "gender") {
      console.log(e.target.value);

      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    } else if (field === "mobile") {
      console.log(e);
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "address") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "state") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "city") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "zipcode") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "country") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "insurance") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "mrn") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "CIN") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  doSave() {
    const { formData } = this.state;
    sessionStorage.getItem("rolename") === "Admin" &&
      this.setState({
        formData: { ...formData, ["org_id"]: sessionStorage.getItem("org_id") },
      });
    let body = {
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      middle_name: this.state.formData.middle_name
        ? this.state.formData.middle_name.charAt(0).toUpperCase() +
          this.state.formData.middle_name.slice(1)
        : "",
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      email: this.state.formData.email.toLowerCase(),
      password: this.state.formData.password,
      //org_id: this.state.formData.org_id,
      consumer_orgs: this.state.formData.consumer_orgs,
      dob: this.state.formData.dob,
      gender: this.state.formData.gender,
      mobile: this.state.formData.mobile
        ? DOMAIN_NAME === "https://staging-test.afya.chat"
          ? "+91" + this.state.formData.mobile
          : "+1" + this.state.formData.mobile
        : this.state.formData.mobile,
      address: this.state.formData.address,
      state: this.state.formData.state,
      city: this.state.formData.city,
      zipcode: this.state.formData.zipcode,
      country: this.state.formData.country,
      MRN: this.state.formData.mrn,
      insurance: this.state.formData.insurance,
      CIN: this.state.formData.CIN ? this.state.formData.CIN : "",
    };
    ApiService.adddetails
      .checkEmail({ email: this.state.formData.email })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false });
          message.error("mail already exist");
        }
      })
      .catch((error) => {
        ApiService.adddetails
          .addPatient(body)
          .then((data) => {
            this.setState({ isLoading: false });
            if (data.status === 200) {
              message.success("Successfully added the consumer");
              this.props.history.push("/consumer-list");
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });

            if (error.data.status === 702) {
              message.error("Mail already exist");
            }
            if (error.data.status === 709) {
              message.error("Npi already exist");
            }
          });
      });
  }

  updateSave() {
    this.setState({ isLoading: true });
    //console.log(this.state.formData.address.length, "length");
    let body = {
      user_id: this.state.formData.user_id,
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      middle_name: this.state.formData.middle_name
        ? this.state.formData.middle_name.charAt(0).toUpperCase() +
          this.state.formData.middle_name.slice(1)
        : "",
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      email: this.state.formData.email.toLowerCase(),
      org_id: this.state.formData.org_id,
      dob: this.state.formData.dob,
      gender: this.state.formData.gender,
      // mobile:
      //   this.state.formData.mobile.length === 10
      //     ? DOMAIN_NAME === "https://staging.afya.chat"
      //       ? "+91" + this.state.formData.mobile
      //       : "+1" + this.state.formData.mobile
      //     : this.state.formData.mobile,
      // address: this.state.formData.address,
      // state: this.state.formData.state,
      // city: this.state.formData.city,
      // zipcode: this.state.formData.zipcode,
      // country: this.state.formData.country,
      // MRN: this.state.formData.mrn,
      // insurance: this.state.formData.insurance,
      CIN: this.state.formData.CIN ? this.state.formData.CIN : "",
    };
    if (this.state.formData.mobile) {
      body.mobile =
        this.state.formData.mobile.length === 10
          ? DOMAIN_NAME === "https://staging-test.afya.chat"
            ? "+91" + this.state.formData.mobile
            : "+1" + this.state.formData.mobile
          : this.state.formData.mobile;
    }
    if (this.state.formData.address) {
      body.address = this.state.formData.address;
    }
    if (this.state.formData.state) {
      body.state = this.state.formData.state;
    }
    if (this.state.formData.city) {
      body.city = this.state.formData.city;
    }
    if (this.state.formData.zipcode) {
      body.zipcode = this.state.formData.zipcode;
    }
    if (this.state.formData.country) {
      body.country = this.state.formData.country;
    }
    if (this.state.formData.insurance) {
      body.insurance = this.state.formData.insurance;
    }
    if (this.state.formData.mrn) {
      body.MRN = this.state.formData.mrn;
    }
    if (this.state.formData.initialEmail === this.state.formData.email) {
      ApiService.adddetails
        .editPatient(body)
        .then((data) => {
          this.setState({ isLoading: false });
          if (data.status === 200) {
            message.success("Successfully updated the consumer");
            this.props.history.push("/consumer-list");
          } else if (data.statusCode === 500) {
            message.error("Company already exist");
          } else if (data.statusCode === 401) {
            this.props.history.push("/login");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });

          if (error.data.statusCode === 500) {
            message.error("Company already exist");
          }
        });
    } else {
      ApiService.adddetails
        .checkEmail({ email: this.state.formData.email })
        .then((res) => {
          if (res.status === 200) {
            message.error("mail already exist");
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          ApiService.adddetails
            .editPatient(body)
            .then((data) => {
              // alert("edit api")
              // console.log(data,'444')
              this.setState({ isLoading: false });
              if (data.status === 200) {
                message.success("Successfully updated the consumer");
                this.props.history.push("/consumer-list");
              } else if (data.statusCode === 500) {
                message.error("Company already exist");
              } else if (data.statusCode === 401) {
                this.props.history.push("/login");
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              if (error.data.statusCode === 500) {
                message.error("Company already exist");
              }
            });
        });
    }
  }

  render() {
    const { Orglist, rolelist, insurancelist, formData } = this.state;
    console.log(this.state.formData, "formdata1");
    let orgTag = Orglist.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let roleTag = rolelist.sort(function (a, b) {
      return a.role_name.localeCompare(b.role_name);
    });

    let insuranceTag = insurancelist.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let listOrg =
      orgTag.length > 0 &&
      orgTag.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let listRole =
      roleTag.length > 0 &&
      roleTag.map((items, i) => {
        return (
          <option key={items.role_id} value={items.role_id}>
            {items.role_name}
          </option>
        );
      }, this);

    let listInsurance =
      insuranceTag.length > 0 &&
      insuranceTag.map((items, i) => {
        return (
          <option key={items.id} value={items.id}>
            {items.name}
          </option>
        );
      }, this);

    let disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    };

    return (
      <Container>
        <h3 className="heading-font">
          {this.state.formData.type === "edit"
            ? "Edit Consumer"
            : "Add Consumer"}
        </h3>
        <Form
          onFinish={() => this.validateForm()}
          initialValues={{
            first_name: this.state.formData.first_name,
            middle_name: this.state.formData.middle_name,
            last_name: this.state.formData.last_name,
            email: this.state.formData.email,
            password: this.state.formData.password,
            Organization: this.state.formData.organization,
            gender: this.state.formData.gender,
            dob: this.state.formData.dob ? moment(this.state.formData.dob) : "",
            Mobile_Number: this.state.formData.mobile
              ? this.state.formData.mobile.substr(
                  this.state.formData.mobile.length - 10
                )
              : "",
            Address: this.state.formData.address,
            State: this.state.formData.state,
            City: this.state.formData.city,
            Zipcode: this.state.formData.zipcode,
            Country: this.state.formData.country,
            Insurance: this.state.formData.insurance,
            mrn: this.state.formData.MRN,
            CIN: this.state.formData.CIN,
          }}
        >
          <FormItem
            label="First Name"
            name="first_name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter first Name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Name",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="First Name"
              onChange={(e) => this.handleChange(e.target.value, "first_name")}
              //onChange={() => this.onFinish()}
            />
          </FormItem>

          <FormItem
            label="Middle Name"
            name="middle_name"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter middle Name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Name",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Middle Name"
              onChange={(e) => this.handleChange(e.target.value, "middle_name")}
              //onChange={() => this.onFinish()}
            />
          </FormItem>

          <FormItem
            label="Last Name"
            name="last_name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter last Name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Name",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Last Name"
              onChange={(e) => this.handleChange(e.target.value, "last_name")}
              //onChange={() => this.onFinish()}
            />
          </FormItem>
          <Form.Item
            label="DOB"
            name="dob"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter a DOB",
              },
            ]}
          >
            <DatePicker
              className="text-input"
              placeholder="Select or enter date in 'MM-DD-YYYY'"
              defaultValue={moment("", dateFormat)}
              disabledDate={disabledDate}
              format={dateFormat}
              value={this.state.formData.dob}
              onChange={(e) => this.handleChange(e, "dob")}
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            className="display"
            rules={[
              {
                required: true,
                message: "Please choose your gender",
              },
            ]}
          >
            <Radio.Group
              style={{ display: "flex" }}
              onChange={(e) => this.handleChange(e, "gender")}
              value={this.state.formData.gender}
            >
              <Radio value={1}>Male</Radio>
              <Radio value={0}>Female</Radio>
            </Radio.Group>
          </Form.Item>
          <FormItem
            name="Mobile_Number"
            label="Mobile"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter Mobile number",
              },
              {
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                message: "Please enter a valid Phone number",
              },
            ]}
          >
            <Input
              className="text-input"
              placeholder="Mobile Number"
              value={this.state.formData.mobile}
              onChange={(e) => this.handleChange(e.target.value, "mobile")}
            />
          </FormItem>
          <Form.Item
            label="Email Id"
            name="email"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
              {
                // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                //pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email id",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Email Id"
              // pattern="\d" //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              onChange={(e) => this.handleChange(e.target.value, "email")}
            />
          </Form.Item>

          {this.state.formData.type === "add" && (
            <Form.Item
              label="Password"
              name="password"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter the password",
                },
                {
                  // pattern: /^[A-Za-z]\w{7,14}$/,
                  // pattern:
                  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  //above pattern in commented so that henseforth all the special chars are accepted in password
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])(?=.{8,})/,
                  message:
                    "Please enter a strong password (min 6 characters which contain special characters,characters, numeric digits  )",
                },
              ]}
            >
              <Input.Password
                className="text-input"
                placeholder="Password"
                onChange={(e) => this.handleChange(e.target.value, "password")}
              />
            </Form.Item>
          )}

          {sessionStorage.getItem("rolename") === "Super Admin" &&
          this.state.formData.type === "add" ? (
            <Form.Item
              label="Organization"
              name="Organization"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please select Organization",
                },
              ]}
            >
              <Select
                showSearch
                className="text-select"
                mode="multiple"
                //labelInValue(using this, will get u the name, key and id in an array)
                placeholder="Select a Organization"
                optionFilterProp="children" //used to search the children value
                onChange={(e) => this.handleChange(e, "consumer_orgs")}
              >
                {listOrg}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item
            label="Address"
            name="Address"
            className="display"

            // rules={[
            //     {
            //         required: true,
            //         message: 'Please enter Address'
            //     },
            // ]}              sprint14 changes

            //style={{ width: '45%' }}
          >
            <TextArea
              rows={4}
              maxLength=""
              placeholder="Address"
              autoComplete="nope"
              value={this.state.formData.address}
              onChange={(e) => this.handleChange(e.target.value, "address")}
            ></TextArea>
          </Form.Item>

          <Form.Item
            label="City"
            name="City"
            className="display"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Please enter City'
            //     },
            //     {
            //         pattern: /^[a-zA-Z ]*$/,
            //         message: 'Please enter a valid city Name'

            //     },
            // ]}             sprint14 changes

            style={{ width: "45%" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="City"
              value={this.state.formData.city || ""}
              onChange={(e) => this.handleChange(e.target.value, "city")}
            />
          </Form.Item>

          {/* <Form.Item
                            label='State'
                            name="State"
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter State'
                                },
                                {
                                    pattern: /^[a-zA-Z ]*$/,
                                    message: 'Please enter a valid state Name'

                                },
                            ]}
                            style={{ width: '45%', float: 'right', marginTop: '-123px' }}
                        >
                            <Input className='text-input'
                                id=""
                                placeholder="State"
                                value={this.state.formData.state || ''}
                                onChange={(e) => this.handleChange(e.target.value,'state')} />
                        </Form.Item> */}

          <FormItem
            name="State"
            label="State"
            className="display"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Please enter State'
            //     },
            // ]}                      sprint14 changes

            style={{ width: "45%", float: "right", marginTop: "-123px" }}
          >
            <Select
              showSearch
              placeholder="State"
              optionFilterProp="children"
              onChange={(e) => this.handleChange(e, "state")}
            >
              <Option value="AL">Alabama</Option>
              <Option value="AK">Alaska</Option>
              <Option value="AZ">Arizona</Option>
              <Option value="AR">Arkansas</Option>
              <Option value="CA">California</Option>
              <Option value="CO">Colorado</Option>
              <Option value="CT">Connecticut</Option>
              <Option value="DE">Delaware</Option>
              <Option value="DC">District of Columbia</Option>
              <Option value="FL">Florida</Option>
              <Option value="GA">Georgia</Option>
              <Option value="HI">Hawaii</Option>
              <Option value="ID">Idaho</Option>
              <Option value="IL">Illinois</Option>
              <Option value="IN">Indiana</Option>
              <Option value="IA">Iowa</Option>
              <Option value="KS">Kansas</Option>
              <Option value="KY">Kentucky</Option>
              <Option value="LA">Louisiana</Option>
              <Option value="ME">Maine</Option>
              <Option value="MD">Maryland</Option>
              <Option value="MA">Massachusetts</Option>
              <Option value="MI">Michigan</Option>
              <Option value="MN">Minnesota</Option>
              <Option value="MS">Mississippi</Option>
              <Option value="MO">Missouri</Option>
              <Option value="MT">Montana</Option>
              <Option value="NE">Nebraska</Option>
              <Option value="NV">Nevada</Option>
              <Option value="NH">New Hampshire</Option>
              <Option value="NJ">New Jersey</Option>
              <Option value="NM">New Mexico</Option>
              <Option value="NY">New York</Option>
              <Option value="NC">North Carolina</Option>
              <Option value="ND">North Dakota</Option>
              <Option value="OH">Ohio</Option>
              <Option value="OK">Oklahoma</Option>
              <Option value="OR">Oregon</Option>
              <Option value="PA">Pennsylvania</Option>
              <Option value="RI">Rhode Island</Option>
              <Option value="SC">South Carolina</Option>
              <Option value="SD">South Dakota</Option>
              <Option value="TN">Tennessee</Option>
              <Option value="TX">Texas</Option>
              <Option value="UT">Utah</Option>
              <Option value="VT">Vermont</Option>
              <Option value="VA">Virginia</Option>
              <Option value="WA">Washington</Option>
              <Option value="WV">West Virginia</Option>
              <Option value="WI">Wisconsin</Option>
              <Option value="WY">Wyoming</Option>
            </Select>
          </FormItem>

          <Form.Item
            label="Zip Code"
            name="Zipcode"
            className="display"
            rules={[
              //     {
              //         required: true,
              //         message: 'Please enter Zip Code'
              //     },
              {
                pattern: /^[0-9]*$/,

                message: "Please enter a valid Zip Code",
              },
            ]}
            sprint14
            changes
            style={{ width: "45%" }}
          >
            <Input
              className="text-input"
              maxLength="5"
              id=""
              placeholder="Zip Code"
              value={this.state.formData.zipcode || ""}
              onChange={(e) => this.handleChange(e.target.value, "zipcode")}
            />
          </Form.Item>
          <Form.Item
            label="Country"
            name="Country"
            className="display"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Please enter country'
            //     },
            //     {
            //         pattern: /^[a-zA-Z ]*$/,
            //         message: 'Please enter a valid country Name'

            //     },
            // ]}                 sprint14 changes

            style={{ width: "45%", float: "right", marginTop: "-123px" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Country"
              value={this.state.formData.country || ""}
              onChange={(e) => this.handleChange(e.target.value, "country")}
            />
          </Form.Item>

          <Form.Item
            label="Insurance"
            name="Insurance"
            className="display"
            rules={[
              {
                required: false,
                message: "Please select insurance",
              },
            ]}
            style={{ width: "45%" }}
          >
            <Select
              showSearch
              className="text-select"
              //labelInValue(using this, will get u the name, key and id in an array)
              placeholder="Insurance"
              optionFilterProp="children" //used to search the children value
              onChange={(e) => this.handleChange(e, "insurance")}
            >
              {listInsurance}
            </Select>
          </Form.Item>
          <Form.Item
            label="MRN"
            name="mrn"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter MRN",
              },
              // {
              //     pattern: /^[a-zA-Z ]*$/,
              //     message: 'Please enter a valid city Name'

              // },
            ]}
            style={{ width: "45%", float: "right", marginTop: "-123px" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="MRN"
              value={this.state.formData.mrn || ""}
              onChange={(e) => this.handleChange(e.target.value, "mrn")}
            />
          </Form.Item>
          <Form.Item
            label="CIN"
            name="CIN"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter CIN",
              },
              // {
              //     pattern: /^[a-zA-Z0-9 ]*$/,
              //     message: 'Please enter a valid state Name'

              // },
            ]}
            style={{ width: "45%" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="CIN"
              value={this.state.formData.CIN || ""}
              onChange={(e) => this.handleChange(e.target.value, "CIN")}
            />
          </Form.Item>

          <Row className="button-padding">
            {this.state.buttonName !== "" && (
              <Button
                className="btn-type"
                id=""
                loading={this.state.isLoading}
                type="primary"
                htmlType="submit"
              >
                {this.state.buttonName}
              </Button>
            )}
            <Link to={{ pathname: "/consumer-list" }}>
              <Button className="btn-type" id="" type="primary">
                Cancel
              </Button>
            </Link>
          </Row>
        </Form>
      </Container>
    );
  }
}
