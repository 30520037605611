import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/analytics";
import CONFIG from "../services/config";

const firebaseConfig =
  CONFIG.DOMAIN_NAME === "https://staging-test.afya.chat"
    ? {
        apiKey: "AIzaSyDwwtKbXsrnFAwVG8dPgNSX9SOiLp_vFmc",
        authDomain: "afya-staging.firebaseapp.com",
        databaseURL: "https://afya-staging.firebaseio.com",
        projectId: "afya-staging",
        storageBucket: "afya-staging.appspot.com",
        messagingSenderId: "878684835542",
        appId: "1:878684835542:web:16bc3fc11f324bc50e0377",
        measurementId: "G-RW3FR0Y8J7",
      }
    : CONFIG.DOMAIN_NAME === "https://uat-test.afya.chat"
    ? {
        apiKey: "AIzaSyAKJre8b5VSARYMjCQ6biPxK_8peiXCE4k",
        authDomain: "afya-uat.firebaseapp.com",
        databaseURL: "https://afya-uat.firebaseio.com",
        projectId: "afya-uat",
        storageBucket: "afya-uat.appspot.com",
        messagingSenderId: "758475956656",
        appId: "1:758475956656:web:01e08a4e877670f27e3675",
        measurementId: "G-XB1V8LR7SJ",
      }
    : {
        apiKey: "AIzaSyDCSysvFS4RtGWqFpZoMJRqmZN9IYgQf4w",
        authDomain: "afya-production.firebaseapp.com",
        databaseURL: "https://afya-production.firebaseio.com",
        projectId: "afya-production",
        storageBucket: "afya-production.appspot.com",
        messagingSenderId: "761816859088",
        appId: "1:761816859088:web:60ce0a2560d7115a8c79d5",
        measurementId: "G-BH860LFLZD",
      };

firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
