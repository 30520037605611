/* eslint-disable eqeqeq */
/* eslint-disable no-throw-literal */
import axios from "axios";
import qs from "qs";
//import { getToken, clearToken } from '../helpers/utility';
import CONFIG from "../services/config";
// import { UNAUTHORIZED } from '../constants/status-code';

import { message } from "antd";

var internals = {};

internals.requestServer = function (
  endPoint,
  data,
  method,
  headers,
  url,
  contentType,
  noAuth,
  domain,
  cancelToken
) {
  let defaultHeaders = {
    Accept: "application/json",
    "Content-Type": contentType || "application/json",
  };
  // if (!noAuth) {
  //     defaultHeaders['auth-token'] = getToken().get('idToken')
  // }
  // if (!noAuth) {
  //   defaultHeaders['auth'] = sessionStorage.getItem('token');
  // }
  defaultHeaders["auth"] = sessionStorage.getItem("token");
  var params = {
    method: method || "GET",
    headers: Object.assign(headers || {}, defaultHeaders),
  };

  if (cancelToken) {
    params.cancelToken = cancelToken.token;
  }

  if (data) {
    if (params.method === "GET") {
      endPoint += (endPoint.indexOf("?") > -1 ? "&" : "?") + qs.stringify(data);
    } else {
      params.data = data;
      // console.log("data >> ",params);
    }
  }

  // if (process.env.NODE_ENV === 'production') {
  //     params.url = 'https://analytics-backend.firstsource.com/api/v1' + endPoint;
  // }
  // else {
  //     params.url = 'http://localhost:3002/api/v1' + endPoint;
  // }

  /**
   * setting environment to call REST Api
   */
  // var hostUrl = window.location.host.split('/')[0].split(':')[0];
  // let envVar = 'development';
  // switch (hostUrl) {
  //   case 'staging.castability.actor':
  //     envVar = 'staging';
  //     break;
  //   case 'www.castability.actor':
  //   case 'castability.actor':
  //   case 'www.greenroom.castability.actor':
  //   case 'greenroom.castability.actor':
  //     envVar = 'production';
  //     break;
  //   default:
  //     envVar = 'development';
  //     break;
  // }

  // if (url === 'admin') {
  //   params.url = CONFIG[envVar].ADMIN_URL + endPoint;
  // } else if (url === 'scripts') {
  //   params.url = CONFIG[envVar].SCRIPT_URL + endPoint;
  // } else if (url === 'notification') {
  //   params.url = CONFIG[envVar].NOTIFICATION_URL + endPoint;
  // } else if (url === 'changepassword') {
  //   params.url = CONFIG[envVar].CHANGEPASSWORD_URL + endPoint;
  // } else if (url === 'auth') {
  //   params.url = CONFIG[envVar].AUTH_URL + endPoint;
  // } else {
  //   params.url = CONFIG[envVar].MASTER_URL + endPoint;
  // }
  params.url = domain + endPoint;

  return axios(params, { crossDomain: true })
    .then(function (response) {
      return response;
    })
    .then(function (response) {
      if (response?.status === 401) {
        //clearToken();
        throw "Invalid Credentials";
      } else {
        // console.log("response>> ",response);
        return response;
      }
    })

    .catch(function (error) {
      if (axios.isCancel(error)) {
        console.log("Request aborted");
        // Handle cancel token error here, such as logging or showing a message
      } else {
        if (error?.response?.status === 401) {
          message.error("Your session is expired");
          sessionStorage.clear();
          window.location.href = "/login";
        }
        throw error?.response; // Throw the error itself instead of error.response
      }
    });
};

export default internals.requestServer;
